import React from "react";

var data = {
	superText: "",
	header: "Improve how you respond and react to your dynamic and high-touch audiences",
	subtext:
		"Top of Mind offers an array of services and features to sort, organize, and better utilize incoming DMs to empowering our users to maximize their connection to customer demand.",
	subHeader: "Give yourself a holistic vision of what interactions are the most important, and kick those time wasters to the curb.",
	image: "",
};

const TwoCol = ({ left, content }) => {
	return (
		<section>
			{left ? (
				<div className={`${content.bgColor} pb-8 sm:pb-12 lg:pb-12 slant-blue`}>
					<div className="pt-3 text-center lg:text-left overflow-hidden sm:pt-12 lg:relative lg:py-36">
						<div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
							<div className="relative w-full h-auto sm:h-72 md:h-96 lg: lg:w-auto lg:h-auto sm:pb-8 lg:pb-0">
								<img
									className={`relative mx-auto inset-0 ${content.imageSize} h-full object-contain`}
									src={content.image}
									alt=""
								/>
							</div>
							<div>
								<div className="flex h-full lg:-mt-10 lg:-ml-20 justify-center items-center">
									<div className="sm:max-w-xl">
										<div className={`mb-8 uppercase text-base font-semibold ${content.textColor}`}>
											{content.superText}
										</div>
										<h1 className={`text-4xl font-bold ${content.textColor} tracking-tight sm:text-5xl lg:text-4xl lg:w-5/6`}>
											{content.header}
										</h1>
										<p className={`mt-10 text-base ${content.textColor} w-60% leading-6 lg:w-2/3`}>
											{content.subtext}
										</p>
										<p className={`mt-10 text-base ${content.textColor} w-60% leading-6 lg:w-2/3 font-bold`}>
											{content.subHeader}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className="bg-white pb-8 sm:pb-12 lg:pb-12">
					<div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-36">
						<div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
							<div className="flex h-full -mt-10 -ml-20 justify-center items-center">
								<div className="uppercase text-base font-medium text-brandblack">
									{data.superText}
								</div>
								<div className="mt-8 sm:max-w-xl">
									<h1 className="text-4xl font-bold text-brandblack tracking-tight sm:text-5xl lg:text-4xl w-5/6">
										{data.header}
									</h1>
									<p className="mt-10 text-base text-brandblack w-60% leading-6 w-2/3">
										{data.subtext}
									</p>
								</div>
							</div>
							<div className="relative w-full h-auto sm:h-72 md:h-96 lg: lg:w-auto lg:h-auto">
								<img
									className="relative inset-0 w-3/4 h-auto object-contain"
									src="/phones/ToM_Chat_Followers.png"
									alt=""
								/>
							</div>
							<div></div>
						</div>
					</div>
				</div>
			)}
		</section>
	);
};

export default TwoCol;
