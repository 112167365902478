import { useState, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch } from "react-redux";
import { setUser } from "../../features/user/userSlice";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../api/axios"
import { Seo } from '../../utils/Seo';

const Signup = () => {
	const errRef = useRef();
	const [errMsg, setErrMsg] = useState("");
	let navigate = useNavigate();
	const dispatch = useDispatch();

	const handleSignup = async (data) => {
		try {
			const response = await axios.post("/api/auth/local/register", data, {
				headers: {
					"Content-Type": "application/json",
				},
			});
			sessionStorage.setItem("userSession", response?.data?.jwt);
			dispatch(setUser(response?.data));
			navigate("/app/add-entity");
		} catch (err) {
			if (!err?.response) {
				setErrMsg("No Server Response");
			} else if (err.response?.status === 400) {
				setErrMsg("Error, please try again");
			} else if (err.response?.status === 401) {
				setErrMsg("Unauthorized");
			} else {
				setErrMsg("Sign up Failed");
			}
			errRef.current.focus();
		}

	};
	return (
		<main className="flex-1 bg-brandwhite">
		{Seo("Sign Up")}
			<div className="pb-8 sm:pb-12 lg:pb-12">
				<div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-36">
					<div className="flex justify-center items-center text-center w-full">
						<div className="flex flex-col h-full justify-center items-center">
							<div className="sm:max-w-xl">
								<div className="mb-8 uppercase text-base font-medium text-brandblack">
									Sign up
								</div>
								<h1 className="text-4xl font-bold text-brandblack tracking-tight sm:text-5xl lg:text-4xl">
								Actively manage your social
								</h1>
								<Formik
									initialValues={{
										firstName: "",
										lastName: "",
										email: "",
										password: "",
									}}
									validate={(values) => {
										const errors = {};
										if (!values.email) {
											errors.email = "Email is Required";
										} else if (
											!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
												values.email
											)
										) {
											errors.email =
												"Invalid email address";
										}
										if (!values.password) {
											errors.password =
												"Password is Required";
										}
										if (values.password.length < 8){
											errors.password = "Password must be at least 8 characters"
										}
										if (!values.firstName) {
											errors.firstName =
												"First Name is Required";
										}
										if (!values.lastName) {
											errors.lastName =
												"Last Name is Required";
										}

										return errors;
									}}
									onSubmit={(values, { setSubmitting }) => {
										values.email = values.email.trim();
										values.firstName =
											values.firstName.trim();
										values.lastName =
											values.lastName.trim();
										let formData = new FormData();
										setTimeout(() => {
											values.username = values.email;

											for (let value in values) {
												formData.append(
													value,
													values[value]
												);
											}

											handleSignup(formData);

											setSubmitting(false);
										}, 400);
									}}
								>
									{({ isSubmitting }) => (
										<Form className="flex flex-col justify-center items-center mt-6">
											<Field
												placeholder="First Name"
												className="w-3/4 my-1.5 px-3 py-2 border border-brandblack rounded-none shadow-sm text-sm font-normal text-brandblack bg-transparent focus:outline-none"
												type="text"
												name="firstName"
											/>
											<ErrorMessage
												name="firstName"
												component="div"
												className="mb-1 text-brandred w-3/4 flex flex-row justify-start items-start"
											/>
											<Field
												placeholder="Last Name"
												className="w-3/4 my-1.5 px-3 py-2 border border-brandblack rounded-none shadow-sm text-sm font-normal text-brandblack bg-transparent focus:outline-none"
												type="text"
												name="lastName"
											/>
											<ErrorMessage
												name="lastName"
												component="div"
												className="mb-1 text-brandred w-3/4 flex flex-row justify-start items-start"
											/>
											<Field
												placeholder="Enter your email"
												className="w-3/4 my-1.5 px-3 py-2 border border-brandblack rounded-none shadow-sm text-sm font-normal text-brandblack bg-transparent focus:outline-none"
												type="email"
												name="email"
											/>
											<ErrorMessage
												name="email"
												component="div"
												className="mb-1 text-brandred w-3/4 flex flex-row justify-start items-start"
											/>
											<Field
												placeholder="Password"
												className="w-3/4 my-1.5 px-3 py-2 border border-brandblack rounded-none shadow-sm text-sm font-normal text-brandblack bg-transparent focus:outline-none"
												type="password"
												name="password"
											/>
											<ErrorMessage
												name="password"
												component="div"
												className="mb-1 text-brandred w-3/4 flex flex-row justify-start items-start"
											/>
											<button
												type="submit"
												disabled={isSubmitting}
												className="mt-2 w-3/4 items-center justify-center px-6 py-2 text-sm text-center font-medium border-0 rounded-none bg-brandorange text-white hover:underline"
											>
												Submit
											</button>
										</Form>
									)}
								</Formik>
								<p className="text-sm text-brandred">{errMsg}</p>
							</div>
							<div className="mt-8 w-1/2 hidden">
								<div className="text-xs text-brandblack">
									Sign up using
								</div>
								<div className="mt-4 flex justify-between w-full">
									<a href="/signup">
										<img
											src="/icons/facebook-icon@2x.png"
											alt=""
											className="w-auto h-auto"
										/>
									</a>
									<a href="/signup">
										<img
											src="/icons/twitter-icon@2x.png"
											alt=""
											className="w-auto h-auto"
										/>
									</a>
									<a href="/signup">
										<img
											src="/icons/google-icon@2x.png"
											alt=""
											className="w-auto h-auto"
										/>
									</a>
								</div>
							</div>
							<div className="mt-8">
								<Link
									to="/login"
									className="uppercase text-brandorange font-bold text-base hover:underline"
								>
									Login
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
};

export default Signup;
