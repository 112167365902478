import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const socketSlice = createSlice({
	name: "socket",
	initialState,
	reducers: {
		setMessage: (state, action) => {
			state.socket = action.payload;
		},
	},
});

export const { setMessage } = socketSlice.actions;
export default socketSlice.reducer;