import React from 'react'
import { PlusCircleIcon } from "@heroicons/react/outline";

function Lead() {
  return (
    <div className="bg-white overflow-hidden shadow rounded-md border-brandblack border-2 h-[10rem] mx-auto lg:mx-0">
      <div className="flex justify-between px-3 py-3 sm:px-3 border-b-2 border-brandblack uppercase text-brandblack text-base font-medium leading-5 tracking-widest">
        <>Lead</>
        <>
					<PlusCircleIcon
						className="hover:text-brandblack hover:cursor-pointer text-brandteal h-[1.5rem] w-[1.5rem]"
					/>
				</>
      </div>
      <div className="px-3 py-2 sm:p-3">{/* Content goes here */}</div>
    </div>
  )
}

export default Lead