import { useState, useRef, useEffect } from "react";
import { PlusCircleIcon, XIcon } from "@heroicons/react/outline";
import { trackPromise } from "react-promise-tracker";
import ReactTooltip from "react-tooltip";
import axios from "../../../api/axios";
import NoteForm from "./NoteForm";
import Markdown from "markdown-to-jsx";
import { LogError } from "../../../utils/LogError";

function Notes({ contactId, notes }) {

  const userSession = sessionStorage.getItem("userSession");
  const [open, setOpen] = useState(false);
  const [editNote, setEditNote] = useState(null);
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");
  const [notesState, setNotesState] = useState(null);

  useEffect(() => {
    setNotesState(notes)
  }, [notes])

  const handleUpdateNotes = (updatedNotes) => {
    setNotesState(updatedNotes);
  };



  const handleNoteDelete = (id) => {
    const remaining = notes.filter((note) => note._id !== id);
    const data = { notes: remaining };

    trackPromise(
      axios
        .put(
          `/api/contacts/${contactId}`,
          { data: data },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userSession}`,
            },
          }
        )
        .then((response) => {
          setNotesState(response.data.data.attributes.notes);
        })
        .catch((error) => {
          console.log(error);
          LogError("Error deleting note", error);
          setErrMsg("Error deleting note, Please try again");
          errRef.current.focus();
        })
    );
  };

  const handleNoteEdit = (note) => {
    setEditNote(note);
    setOpen(true);
  };

  return (
    <div className="bg-white overflow-hidden shadow rounded-md border-brandblack border-2 h-[10rem] mx-auto lg:mx-0">
      <div className="flex justify-between px-3 py-3 sm:px-3 border-b-2 border-brandblack uppercase text-brandblack text-base font-medium leading-5 tracking-widest">
        <div>Notes</div>
        <div>
          <ReactTooltip
            border={true}
            borderColor={"rgb(62 82 97)"}
            backgroundColor={"#FFF"}
            textColor={"text-brandBlack"}
          />
          <PlusCircleIcon
            data-tip="Add Note"
            onClick={() => {
              setEditNote(null);
              setOpen(true);
            }}
            className="hover:text-brandblack hover:cursor-pointer text-brandteal h-[1.5rem] w-[1.5rem]"
          />
        </div>
      </div>
      <div className="px-3 text-xs text-brandblack overflow-y-scroll h-[6.25rem] scrollbar-hide">
        {notesState &&
          notesState.map((note) => (
            <div
              className="flex justify-between items-center py-2 border-b-[1px]"
              key={note._id}
              
            >
              <div className="overflow-hidden truncate w-75 cursor-pointer  hover:text-brandteal hover:font-bold transition-all" onClick={() => handleNoteEdit(note)}>
                <Markdown options={{ forceBlock: true }}>
                  {note.note.substring(0, 100)}
                </Markdown>
              </div>
              <>
                <XIcon
                  className="h-[1rem] w-[1rem] hover:text-brandteal hover:cursor-pointer text-brandorange"
                  onClick={() => handleNoteDelete(note._id)}
                />
              </>
            </div>
          ))}
        {!notesState || notesState.length === 0 ? (
          <p>No Notes to display</p>
        ) : null}
        <p className="text-sm text-brandred">{errMsg}</p>
      </div>
      {open &&
        <NoteForm
          open={open}
          setOpen={setOpen}
          contactId={contactId}
          notesState={notesState}
          setNotesState={setNotesState}
          note={editNote}
          setEditNote={setEditNote}
          handleUpdateNotes={handleUpdateNotes} // Add this line

        />}
    </div>
  );
}
export default Notes;
