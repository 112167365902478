import {useEffect, useState} from "react";
import { trackPromise } from "react-promise-tracker";
import axios from "axios";
import { LogError } from "../../../utils/LogError";
const ProfileOverview = () => {
	const FBPageAccess = sessionStorage.getItem("FBPageAccess");
	const entitySGID = JSON.parse(sessionStorage.getItem("entity")).sgId;
	const [myInsta, setMyInsta] = useState(null)

	useEffect(() => {
		trackPromise(
			axios.get(`https://graph.facebook.com/v12.0/${entitySGID}?access_token=${FBPageAccess}&fields=name,media_count,profile_picture_url,follows_count,followers_count,ig_id,id,username`)
			.then((response) => {
				setMyInsta({...response.data})
			}).catch((err) => {
				console.log(err);
				LogError("Error Occurred, Getting Entity Instagram Profile Details", err);
			})
		)
	},[entitySGID, FBPageAccess])
	return (
		<div className="px-4 py-8 sm:p-6">
			<div className="flex flex-row items-start">
				<div className="flex flex-1 flex-col items-start">
					<img
						src={myInsta?.profile_picture_url}
						alt=""
						className="w-2/3 h-auto rounded-full"
					/>
				</div>
				<div className="flex flex-1 flex-col items-start ">
					<div className="pb-2 uppercase text-brandblack text-base font-bold leading-5 tracking-widest">
						{myInsta?.name}
					</div>
					<div className="py-2 text-brandteal text-base font-light">
						{myInsta?.followers_count} Followers
					</div>
					<div className="py-2 text-brandteal text-base font-light">
						Following {myInsta?.follows_count}
					</div>
					<div className="py-2 text-brandteal text-base font-light">
						{myInsta?.media_count} Posts
					</div>
					<div className="py-3 text-brandorange border-b border-brandorange leading-4 text-xs">
						<a href={`https://instagram.com/${myInsta?.username}`} target="_blank">View More</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProfileOverview;
