import ContactsList from "../../components/app/dashboard/ContactsList";
import ConversationList from "../../components/app/dashboard/ConversationList";
import ProfileOverview from "../../components/app/dashboard/ProfileOverview";
import FollowerChart from "../../components/app/dashboard/FollowerChart";
import { Seo } from '../../utils/Seo';

const Contacts = () => {
	return (
		<main className="mx-20 my-10 h-full flex flex-col items-center justify-center">
		{Seo('Contact')}
			<div className="m-6 items-center justify-between lg:grid lg:grid-cols-1 lg:gap-4 gap-4 w-full">
			<div className="bg-white overflow-hidden shadow rounded-md border-brandblack border-2 h-[32rem] lg:mx-0 sm:my-8">
				<ContactsList />
				</div>
			</div>
		</main>
	);
};

export default Contacts;
