import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/user/userSlice";
import entityReducer from "./features/entity/entitySlice";
import contactsReducer from "./features/contacts/contactsSlice";
import conversationReducer from "./features/conversations/conversationSlice";
import socketReducer from "./features/socket/socketSlice"

export const store = configureStore({
	reducer: {
		user: userReducer,
        entity: entityReducer,
		contacts: contactsReducer,
		conversation: conversationReducer,
		socket: socketReducer
	},
});
