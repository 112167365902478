import React from 'react';
import { Helmet } from 'react-helmet';

export const Seo = (pageTitle) => {
  const siteName = 'Top of Mind';

  return (
    <Helmet>
      <title>{`${siteName} - ${pageTitle}`}</title>
    </Helmet>
  );
};
