import Hero from "../../components/site/LandingPage/Hero";
import TwoCol from "../../components/site/LandingPage/TwoCol";
import TwoColIcons from "../../components/site/LandingPage/TwoColIcons";
import OneCol from "../../components/site/LandingPage/OneCol";
import { Helmet } from "react-helmet";
// import Testimonials from "../../components/site/LandingPage/Testimonials";

var twoColLeft1 = {
	superText: "Transparent inboxes",
	header: "Improve how you respond and react to your dynamic and high-touch audiences",
	subtext:
		"Top of Mind offers an array of services and features to sort, organize, and better utilize incoming DMs to empowering our users to maximize their connection to customer demand",
	image: "/phones/tom-list-conversation.png",
	imageSize: "w-3/4",
	subHeader: "Give yourself a holistic vision of what interactions are the most important, and kick those time wasters to the curb.",
	bgColor: "bg-brandblack",
	textColor: "text-brandwhite",
};
var twoColIconsLeft = {
	superText: "Purpose built for efficiency + Organization",
	header: "Designed on Instagram and made for influencers and businesses of all sizes",
	leftColHeader: "Businesses",
	leftColFirst: "Engage with interested customers and brand ambassadors",
	leftColSecond: "Accurately target different tiers of outreach",
	rightColHeader: "Influencers",
	rightColFirst: "Never miss an inbound lead",
	rightColSecond: "Build a list of favorite brands to represent",
	image: "/phones/ToM_Profile.png",
};
var twoColLeft2 = {
	superText: "Easy-to-use customer service",
	header: "Reactive and Proactive Capabilities Keep Every Opportunity Warm",
	subtext: "Respond to customer inquiries, sort and arrange messages, flag priorities for different business opportunities, schedule meetings and set follow up reminders to never miss revenue",
	image: "/phones/ToM_Analytics.png",
	imageSize: "w-1/2",
	bgColor: "bg-brandwhite",
	textColor: "text-brandblack",
};
var oneCol1 = {
	superText: "Organize Your Inbox",
	header: "Sort, tag, identify spam, and organize your inbox",
	subtext: "",
	image: "/charts/conversation_list.png",
	imageSize: "w-3/4",
	bgColor: "bg-brandblack",
	textColor: "text-brandwhite"
};
var twoColIconsRight = {
	superText: "Security",
	header: "Secure sign-in for DM management only, allowing governance over posting and DM responses",
	subtext: "",
	image: "",
	imageSize: "",
	bgColor: "bg-brandblack"
};
var twoColLeft3 = {
	superText: "Manage and Grow your Sales Pipeline",
	header: "Data Analytics and Message Optimization",
	subtext: "Our data analytics provide insights into potential new business, pipeline opportunities, and track existing agreements",
	image: "/charts/pipeline@2x.png",
	subHeader: "Never miss “follow up” chances to re-engage with interested parties",
	imageSize: "w-3/4",
	bgColor: "bg-brandwhite",
	textColor: "text-brandblack"
};
/*
var testimonial = [
	{
		id: 1,
		image: "/people/3@2x.png",
		name: "Alisa G.",
		handle: "@alisag",
		followers: "125.4K followers",
		stars: 5,
		body: "Top of Mind helped me manage heaps of instagram messages so I could stay connected to my audience.",
		followURL: "#",
	},
	{
		id: 2,
		image: "/people/3@2x.png",
		name: "Alisa G.",
		handle: "@alisag",
		followers: "125.4K followers",
		stars: 5,
		body: "Top of Mind helped me manage heaps of instagram messages so I could stay connected to my audience.",
		followURL: "#",
	},
	{
		id: 3,
		image: "/people/3@2x.png",
		name: "Alisa G.",
		handle: "@alisag",
		followers: "125.4K followers",
		stars: 5,
		body: "Top of Mind helped me manage heaps of instagram messages so I could stay connected to my audience.",
		followURL: "#",
	},
	{
		id:4,
		image: "/people/3@2x.png",
		name: "Alisa G.",
		handle: "@alisag",
		followers: "125.4K followers",
		stars: 5,
		body: "Top of Mind helped me manage heaps of instagram messages so I could stay connected to my audience.",
		followURL: "#",
	},
	{
		id:5,
		image: "/people/3@2x.png",
		name: "Alisa G.",
		handle: "@alisag",
		followers: "125.4K followers",
		stars: 5,
		body: "Top of Mind helped me manage heaps of instagram messages so I could stay connected to my audience.",
		followURL: "#",
	},
];
*/
var oneCol2 = {
	superText: "",
	header: "Want to see how it works?",
	subtext: "",
	// image: "/charts/demo_thumbnail.png",
	// imageSize: "w-3/4",
	bgColor: "bg-brandpink",
};

const LandingPage = () => {
	return (
		<>
			<Helmet>
				<title>Top of Mind</title>
			</Helmet>

			<Hero />
			<TwoCol left={true} content={twoColLeft1} />
			<TwoColIcons left={true} content={twoColIconsLeft} />
			<TwoCol left={true} content={twoColLeft2} />
			<OneCol content={oneCol1} button={false} />
			<TwoColIcons left={false} content={twoColIconsRight} />
			<TwoCol left={true} content={twoColLeft3} />
			<OneCol content={oneCol2} button={true} />
		</>
	);
};

export default LandingPage;
