import {useEffect, useState} from 'react';
import io from 'socket.io-client';
import { useDispatch } from "react-redux";
import { setMessage } from '../features/socket/socketSlice';

const Socket = () => {
  const dispatch = useDispatch();
    const user = JSON.parse(sessionStorage.getItem("user"));
    const userSession = sessionStorage.getItem('userSession');
    const [entity, setEntity] = useState(JSON.parse(sessionStorage.getItem("entity")) || {})
    //const entity = JSON.parse(sessionStorage.getItem("entity"));

    useEffect(() => {
      setEntity(JSON.parse(sessionStorage.getItem("entity")))
    },[])
    
    useEffect(() => {
      // console.log(entity);
        const socket = io.connect(process.env.REACT_APP_BACKEND_URL, {
          auth: {
            token: userSession
          }
        });

        socket.emit('join', {
          'userId': user.id,
          'entityId': entity.id,
          'igSid': entity.sgId,
          'userToken': userSession
        });

        socket.on("messageReceived", (data) => {
          // console.log(data)
          dispatch(setMessage({
            message: data?.messaging[0]?.message?.text,
            senderId: data?.messaging[0]?.sender?.id
          }))
    
        });

        socket.on("eventTest", (data) => {
          console.log(data); // "G5p5..."
        });
    
    
      },[])

    return(<></>)
}

export default Socket;