import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { trackPromise } from "react-promise-tracker";
import axios from "../../../api/axios"
import { v4 as uuidv4 } from "uuid";
import { Calendar } from 'react-date-range';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { LogError } from "../../../utils/LogError";

const AddTask = ({ open, setOpen, contactId, tasks, editTask, addEdit, tasksState, setTasksState }) => {
	const userSession = sessionStorage.getItem("userSession");
	const cancelButtonRef = useRef(null);
	const errRef = useRef();
	const [errMsg, setErrMsg] = useState("");
	const [selectedDate, setSelectedDate] = useState(new Date());

	useEffect(() => {
		if (!addEdit) {
			setSelectedDate(new Date(editTask?.dueDate))
		}
	}, [editTask])

	const handleSelect = (date) => {
		setSelectedDate(date)
	}
	const handleCancel = () => {
		setOpen(false);
		setSelectedDate(new Date());
	}

	const handleAddTask = (task) => {
		if (tasksState === null) {
			setTasksState([])
		}
		var data = {}
		if (addEdit) {
			data = {
				tasks: [
					...tasksState,
					{
						_id: uuidv4(),
						task: task.task,
						complete: false,
						created_at: new Date(),
						updated_at: new Date(),
						dueDate: selectedDate,
						taskDescription: task.taskDescription
					},
				],
			};
		} else {
			var updatedTask = {
				_id: editTask._id,
				task: task.task,
				complete: false,
				created_at: editTask.created_at,
				updated_at: new Date(),
				dueDate: selectedDate,
				taskDescription: task.taskDescription
			}
			tasksState[tasksState.findIndex(el => el.id === updatedTask.id)] = updatedTask;
			data = {
				tasks: [...tasksState]
			}
		}

		trackPromise(
			axios
				.put(`/api/contacts/${contactId}`,
					{ data: data },
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${userSession}`,
						},
					}
				)
				.then((response) => {
					// console.log(response.data.data.attributes.tasks)
					setTasksState(response.data.data.attributes.tasks);
					setOpen(false)
					// window.location.reload(false);
				})
				.catch((error) => {
					LogError("Error, Adding Task", error);
					setErrMsg("Error Occured, Please try again");
					errRef.current.focus();
				})
		);
	};

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				className="fixed z-10 inset-0"
				initialFocus={cancelButtonRef}
				onClose={() => setOpen}
			>
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true"
					>
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
							<div>
								<div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
									<CheckIcon
										className="h-6 w-6 text-green-600"
										aria-hidden="true"
									/>
								</div>
								<div className="mt-3 text-center sm:mt-5">
									<Dialog.Title
										as="h3"
										className="text-lg leading-6 font-medium text-gray-900"
									>
										{addEdit ? 'Add a New Task' : 'Edit Task'}
									</Dialog.Title>
									<div className="mt-2">
										<Formik
											initialValues=
											{editTask ? {
												task: editTask?.task,
												dueDate: editTask?.dueDate,
												taskDescription: editTask?.taskDescription
											}
												: { task: '', taskDescription: '' }}
											validate={(values) => {
												const errors = {};
												if (!values.task) {
													errors.task =
														"This field is Required";
												}

												return errors;
											}}
											onSubmit={(
												values,
												{ setSubmitting }
											) => {
												values.task =
													values.task.trim();

												setTimeout(() => {
													handleAddTask(values);

													setSubmitting(false);
												}, 400);
											}}
										>
											{({ isSubmitting }) => (
												<Form className="flex flex-col justify-center items-center mt-6">
													<Field
														placeholder="Task Name"
														className="w-3/4  my-1.5 px-3 border border-brandblack rounded-none shadow-sm text-sm font-normal text-brandblack bg-transparent focus:outline-none"
														as="input"
														name="task"
													/>
													<ErrorMessage
														name="task"
														component="div"
														className="mb-1 text-brandred w-3/4 flex flex-row justify-start items-start"
													/>
													<Field
														placeholder="Task Description"
														className="w-3/4  my-1.5 px-3 border border-brandblack rounded-none shadow-sm text-sm font-normal text-brandblack bg-transparent focus:outline-none"
														as="textarea"
														name="taskDescription"
													/>
													<ErrorMessage
														name="task"
														component="div"
														className="mb-1 text-brandred w-3/4 flex flex-row justify-start items-start"
													/>
													<Calendar
														minDate={new Date()}
														color={"#FCE3DE"}
														date={selectedDate}
														onChange={handleSelect}
													/>
													<button
														type="submit"
														disabled={isSubmitting}
														className="mt-2 w-3/4 items-center justify-center px-6 py-2 text-lg text-center font-bold border-0 rounded-none bg-brandorange text-white hover:underline"
													>
														Submit
													</button>
												</Form>
											)}
										</Formik>
										<p className="text-sm text-brandred">
											{errMsg}
										</p>
									</div>
								</div>
							</div>
							<div className="mt-2 sm:mt-2">
								<button
									type="button"
									className="inline-flex justify-center w-full shadow-sm px-4 py-2 text-xl uppercase font-bold text-brandteal hover:text-brandblack focus:outline-none sm:text-sm"
									onClick={() => handleCancel()}
									ref={cancelButtonRef}
								>
									No, Cancel
								</button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default AddTask;
