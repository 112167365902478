import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateUser } from "../../features/user/userSlice";
import { trackPromise } from "react-promise-tracker";
import AppHeader from "./AppHeader";
import Socket from "../../socket/Socket";
import axios from "../../api/axios";
import { LogError } from "../../utils/LogError";

const AppLayout = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const userSession = sessionStorage.getItem("userSession");
	const [title, setTitle] = useState("");
	const [name, setName] = useState("");

	// Set Title
	useEffect(() => {
		if (location.pathname === "/app") {
			setTitle("Dashboard");
		} else if (location.pathname.includes("/contact")) {
			setTitle("Contact");
		} else {
			setTitle(location.pathname.replace("/app/", ""));
		}
	}, [location]);

	useEffect(() => {
		const userDetails = () => {
			trackPromise(
				axios
					.get("/api/users/me/details", {
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${userSession}`,
						},
					})
					.then((response) => {
						setName(response.data.firstName);
						//updates user with lists and entities
						dispatch(updateUser(response.data));
					})
					.catch((err) => {
						console.log(err);
						LogError("Error Occurred, Getting User Details", err);
					})
			);
		};

		userDetails();
	}, [dispatch, userSession]);

	return (
		<div className="App flex flex-col min-h-fit lg:h-screen md:h-full">
			<Socket />
			<AppHeader title={title} name={name} />
			<Outlet />
		</div>
	);
};

export default AppLayout;
