import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import axios from "axios";
import { useDispatch } from "react-redux";
import Chat from "../../components/app/Chat";
import ContactUtil from "../../components/app/contact/ContactUtil";
import { setContacts } from "../../features/contacts/contactsSlice";
import { ChatIcon } from "@heroicons/react/outline";
import { Seo } from '../../utils/Seo';
import { LogError } from "../../utils/LogError";


const Contact = () => {
	let navigate = useNavigate();
	const dispatch = useDispatch();
	const userSession = sessionStorage.getItem("userSession");
	const FBPageAccess = sessionStorage.getItem("FBPageAccess");
	const contacts = JSON.parse(sessionStorage.getItem("contacts"));
	const { id } = useParams();
	const [currentContact, setCurrentContact] = useState(false);
	const [instaDetails, setInstaDetails] = useState(false)

	useEffect(() => {
		dispatch(setContacts(contacts));
	}, [contacts, dispatch])

	const handleConversation = (id, SGID, username) => {
		navigate(`/app/conversation`, { state: { id: id, SGID: SGID, username: username } });
	};

	useEffect(() => {
		trackPromise(
			axios
				.get(
					process.env.REACT_APP_BACKEND_URL + "/api/contacts/" + id + '?populate=lists',
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${userSession}`,
						},
					}
				)
				.then((response) => {
					const cID = response.data.data.id;
					const cObj = response.data.data.attributes;
					setCurrentContact({ id: cID, ...cObj });
				})
				.catch((err) => {
					console.log(err);
					LogError("Error Occurred, Getting Contacts", err);
				})
		);
	}, [id, userSession]);

	useEffect(() => {
		if (currentContact) {
			trackPromise(
				axios.get(`https://graph.facebook.com/v12.0/${currentContact.instagramSId}?access_token=${FBPageAccess}&fields=name,profile_pic,follower_count,is_user_follow_business,is_business_follow_user`)
					.then((response2) => {
						setInstaDetails({ ...response2.data })
					}).catch((err) => {
						console.log(err);
						LogError("Error Occurred, Getting Contact Instagram Details", err);
					})
			)
		}
	}, [currentContact, FBPageAccess])

	return (
		<div className=" overflow-hidden lg:relative lg:max-w-full lg:max-h-full">
		{Seo('Contact' + " - " + currentContact.firstName + ' ' + currentContact.lastName)}
			<div className="md:mx-20 my-10 md:mx-0">
				<div className="m-6 items-center grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4">
					<div
						className={`flex flex-col bg-white overflow-hidden shadow rounded-md border-brandblack border-2 h-full mx-auto lg:mx-0 w-full`}
					>
						
						<Chat conversationId={currentContact.conversationId} />
					</div>
					<div
						className={`flex flex-col bg-white overflow-hidden shadow rounded-md border-brandblack border-2 h-full mx-auto lg:mx-0 lg:col-span-2`}
					>
						<ContactUtil currentContact={currentContact} instaDetails={instaDetails} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Contact;
