import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Link, useNavigate } from "react-router-dom";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { ChevronDownIcon, ChevronUpIcon, PlusCircleIcon } from "@heroicons/react/solid";
import { removeUser } from "../../features/user/userSlice";
import { useDispatch } from "react-redux";
import TomLogo from "../../assets/ToM_Lockup_Beta.png";

const AppHeader = ({ title, name }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(removeUser());
    sessionStorage.clear();
    navigate("/login");
  };

  return (
    <header className="max-w-full mx-auto px-4 sm:px-6 relative bg-white w-full">
      <div className="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
        <Popover.Group className="flex justify-between items-center ">
          <Popover className="flex justify-between items-center">
            <Popover.Button className="bg-transparent inline-flex items-center justify-center text-brandblack focus:outline-none">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-8 w-8" aria-hidden="true" />
            </Popover.Button>
            {/* LEFT POPOUT MENU */}
            <Transition
              as={Fragment}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                className="absolute top-0 left-0 right-3/4 bottom-0  transition transform origin-top-left z-20"
              >
                <div className="shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                  <div className="py-5 border-b-2">
                    <div className="flex items-center justify-start">
                      <div className="">
                        <Popover.Button className="bg-white p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none ">
                          <span className="sr-only">Close menu</span>
                          <XIcon
                            className="h-8 w-8"
                            aria-hidden="true"
                          />
                        </Popover.Button>
                      </div>
                    </div>
                  </div>
                  <div className="py-6 px-5 space-y-6">
                    <div>
                      <Link
                        to="/app/dashboard"
                        className="w-full py-1 flex items-center justify-start text-base font-normal text-brandblack hover:underline"
                      >
                        Dashboard
                      </Link>

                      <hr className="text-transparent border-none my-4" />
                      <Link
                        to="/app/conversations"
                        className="w-full py-1 flex items-center justify-start text-base font-normal text-brandblack hover:underline"
                      >
                        Conversations
                      </Link>

                      <hr className="text-transparent border-none my-4" />
                      <Link
                        to="/app/contacts"
                        className="w-full py-1 flex items-center justify-start text-base font-normal text-brandblack hover:underline"
                      >
                        Contacts
                      </Link>
                    </div>
                  </div>
                </div>
				</Popover.Panel>
        </Transition>
        <div className="uppercase text-brandblack text-base font-bold ml-4">
          {title}
        </div>
      </Popover>
    </Popover.Group>
    <Link
      to="/app/dashboard"
      className="flex md:flex items-center justify-center md:flex-1"
    >
      <img src={TomLogo} className="sm:h-12 md:h-16 w-auto" alt="Tom logo" />
    </Link>

    <Popover.Group className="flex justify-center items-center">
      <div className="hidden md:flex items-center justify-end md:flex-1">
        <a
          href="mailto:hello@topofmind.social"
          className="whitespace-nowrap text-sm font-normal text-brandblack hover:underline"
        >
          How can we improve?
        </a>
      </div>
      <div className="flex justify-center items-center">
        <div className="mr-4 text-brandblack font-normal text-sm">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Hello, {name}
        </div>
      </div>
      <Popover>
        <Popover.Button className=" bg-transparent text-brandblack focus:outline-none ">
          <ChevronDownIcon className="h-8 w-8 " aria-hidden="true" />
        </Popover.Button>
        {/* RIGHT POPOUT MENU */}
        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel className="absolute z-20 top-0 right-0 left-5/6  transition transform origin-top-left">
            <div className="shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="py-5 border-b-2">
                <div className="flex items-center justify-start">
                  <div className="flex justify-center items-center">
                    <div className="ml-4 mr-4 text-brandblack font-normal text-sm">
                      Hello, {name}
                    </div>
                    <Popover.Button className="bg-white p-2 inline-flex items-center justify-center text-brandblack hover:text-gray-500 hover:bg-transparent focus:outline-none ">
                      <span className="sr-only">Close menu</span>
                      <ChevronUpIcon
                        className="h-6 w-6"
                        aria-hidden="true"
                      />
                    </Popover.Button>
                  </div>
                </div>
              </div>
              <div className="py-6 px-5 space-y-6">
                <div>
                  <a
                    href="https://docs.google.com/forms/d/1coZfTHOHUXBKszAYk3WFYy1oLz3RfBQmS2griUv6OCk/edit?ts=63e56475"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-full py-1 flex items-center justify-start text-base font-normal text-brandblack hover:underline hover:cursor-pointer"
                  >
                    Customer Feedback
                  </a>
                  <a
                    href="mailto:hello@topofmind.social"
                    to="/"
                    className="w-full py-1 flex items-center justify-start text-base font-normal text-brandblack hover:underline hover:cursor-pointer"
                  >
                    Support
                  </a>
                  <div
                    className="w-full py-1 flex items-center justify-start text-base text-brandblack hover:underline hover:cursor-pointer"
                    onClick={logout}
                  >
                    Logout
                  </div>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </Popover.Group>
  </div>
</header>
  );

  
};

export default AppHeader;
