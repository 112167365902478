import React, { useState } from "react";
import ListItem from "../../app/ListItem";
import MessageList from "../../app/conversation/MessageList";

const ConversationList = ({selectedUserId}) => {
	const entity = JSON.parse(sessionStorage.getItem('entity'));
	const [activeItem, setActiveItem] = useState(entity?.lists[0]?.listName);

	return (
		<>
			<div className="px-4 py-5 sm:px-6 border-b-2 border-brandblack uppercase text-brandblack text-base font-bold leading-5 tracking-widest">
				Conversations
			</div>
			<div className="flex h-full">
				{/* <div
					className={`flex flex-col w-[10rem] h-[36rem] scrollbar-hide justify-start border-r-2 border-brandblack pt-3 pr-4 overflow-ellipsis overflow-y-scroll`}
				>
					{entity.lists.map((list) => (
						<div
							key={list.id}
							onClick={() => {
								setActiveItem(list.listName);
							}}
						>
							<ListItem
								active={activeItem}
								listItem={list.listName}
							/>
						</div>
					))}
				</div> */}
				<div
					className={`flex flex-col overflow-y-scroll w-full h-[36rem] scrollbar-hide`}
				>
					<MessageList selectedUserId={selectedUserId}/>
				</div>
			</div>
		</>
	);
};

export default ConversationList;
