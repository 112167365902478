import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { trackPromise } from "react-promise-tracker";
import fb from '../../../../api/fb'
import axios from "../../../../api/axios";
import { LogError } from "../../../../utils/LogError";

const EditContact = ({ openEdit, setOpenEdit, igHandle, scopeId, entity, owner, convoId, contact }) => {
	const userSession = sessionStorage.getItem("userSession");
	const user = JSON.parse(sessionStorage.getItem('user'));
	const entityData = JSON.parse(sessionStorage.getItem('entity'));
	const cancelButtonRef = useRef(null);
	const errRef = useRef();
	const [errMsg, setErrMsg] = useState("");
	const [igData, setIgData] = useState('');
	const [lists, setLists] = useState('');
	const [openAuth, setOpenAuth] = useState(false);
	const FBPageAccess = sessionStorage.getItem('FBPageAccess')

	const handleEditContact = (contact, values) => {
		const data = {
			"firstName": values.firstName,
			"lastName": values.lastName,
			"instagramHandle": contact.instagramHandle,
			"instagramId": '',
			"instagramSId": contact.instagramSId,
			"status": "new",
			"lists": [{ id: values.contactList ?  values.contactList : contact.lists?.data[0]?.id  }],
		};

		trackPromise(
			axios
				.put("/api/contacts/"+contact.id,
					{ data: data },
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${userSession}`,
						},
					}
				)
				.then((response) => {
					window.location.reload(false);
				})
				.catch((error) => {
					setErrMsg("Error adding Contact, Please try again");
					LogError("Error, adding contact", error);
					if(error.response.status === 400) {
						setErrMsg("Error, contact already exists");
						LogError("Error, contact already exists", error);
					}
					errRef.current.focus();
				})
		);
	};

	
	useEffect(() => {
		const handleGetLists = async () => {
			axios
				.get("/api/lists",
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${userSession}`,
						},
					}
				)
				.then((response) => {
					// console.log(response)
					setLists(response?.data?.data)
				})
				.catch((error) => {
					console.log(error);
					setErrMsg("Error getting lists, Please try again");
					LogError("Error, getting lists", error);
					errRef.current.focus();
				})


}

		const getContacts = async () => {
			const arrayData = [];

			try {
				const fbResponse = await fb.get(`/${contact.instagramSId}?access_token=${FBPageAccess}&fields=name,profile_pic,follower_count,is_user_follow_business,is_business_follow_user`);
				arrayData['id'] = fbResponse?.data?.id;
				arrayData['name'] = fbResponse?.data?.name;
				arrayData['follower_count'] = fbResponse?.data?.follower_count;
				arrayData['profile_pic'] = fbResponse?.data?.profile_pic;
				setIgData(arrayData);

			} catch (err) {
				if (err.code === 109) {
					console.log('cont1', err);
					sessionStorage.removeItem('fbSession');
					setOpenAuth(true)
				}
				console.log(err)
				LogError("Error, getting dddcontact", err);

			}



		}
		handleGetLists();
		getContacts();
		
	}, [scopeId]);

	return (
		<Transition.Root
			show={openEdit}
			as={Fragment}

		>
			<Dialog as="div" className="fixed z-10 inset-0" initialFocus={cancelButtonRef} onClose={() => setOpenEdit}>
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true"
					>
						&#8203;
					</span>
					<Transition.Child

						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
							<div>
								<div className="mx-auto flex items-center justify-center">
									<div className="flex space-x-10 items-center">
										<div className="relative">
											{igData?.profile_pic ? (
												<img
													src={
														igData?.profile_pic
													}
													alt=""
													className="w-[48px] h-auto rounded-full"
												/>
											) : (<img
												src="\people\default-user.png"
												alt=""
												className="w-[48px] h-auto rounded-full"
											/>)}

										</div>
										<div className="flex-1 space-y-1">

											<div className="flex-col justify-between items-center gap-3">
												<div className="text-sm font-normal text-brandblack">
													{igData.name}
												</div>
											</div>
											<div className="flex justify-between">
												<div className="flex justify-between text-sm text-brandteal">
													<span className="">
														{igData.follower_count} Followers
													</span>
												</div>
											</div>


										</div>
									</div>
								</div>
								<div className="mt-3 text-center sm:mt-5">
									<Dialog.Title
										as="h3"
										className="text-lg leading-6 font-medium text-gray-900"
									>
										Add to Contacts
									</Dialog.Title>
									<div className="mt-2">

										<Formik

											initialValues={{
												igHandle: contact?.instagramHandle,
												firstName: contact?.firstName,
												lastName: contact?.lastName,
											}}
											validate={(values) => {
												const errors = {};
												if (!values.igHandle) {
													errors.igHandle =
														"Instagram handle is Required";
												}
												if (!values.firstName) {
													errors.firstName =
														"First Name is Required";
												}
												if (!values.lastName) {
													errors.lastName =
														"Last Name is Required";
												}

												return errors;
											}}
											onSubmit={(
												values,
												{ setSubmitting }
											) => {
												values.igHandle =
													values.igHandle.trim();
												values.firstName =
													values.firstName.trim();
												values.lastName =
													values.lastName.trim();

												setTimeout(() => {
													handleEditContact(contact, values);

													setSubmitting(false);
												}, 400);
											}}
										>
											{({ isSubmitting }) => (
												<Form className="flex flex-col justify-center items-center mt-6">
													<Field
														placeholder="Instagram Handle"
														className="w-3/4 my-1.5 px-3 py-2 border border-brandblack rounded-none shadow-sm text-sm font-normal text-brandblack bg-gray-200 focus:outline-none hover:cursor-not-allowed"
														type="text"
														name="igHandle"
														disabled={true}
													/>
													<ErrorMessage
														name="igHandle"
														component="div"
														className="mb-1 text-brandred w-3/4 flex flex-row justify-start items-start"
													/>
													<Field

														placeholder="Contact First name"
														className="w-3/4 my-1.5 px-3 py-2 border border-brandblack rounded-none shadow-sm text-sm font-normal text-brandblack bg-transparent focus:outline-none"
														type="text"
														name="firstName"
													/>
													<ErrorMessage
														name="firstName"
														component="div"
														className="mb-1 text-brandred w-3/4 flex flex-row justify-start items-start"
													/>
													<Field
														placeholder="Contact Last Name"
														className="w-3/4 my-1.5 px-3 py-2 border border-brandblack rounded-none shadow-sm text-sm font-normal text-brandblack bg-transparent focus:outline-none"
														type="text"
														name="lastName"
													/>
													<ErrorMessage
														name="lastName"
														component="div"
														className="mb-1 text-brandred w-3/4 flex flex-row justify-start items-start"
													/>
													<Field
														name="contactList"
														as="select"
														className="w-3/4 my-1.5 px-3 py-2 border border-brandblack rounded-none shadow-sm text-sm font-normal text-brandblack bg-transparent focus:outline-none"
													>
														<option value={""}>
															Select a Contact List
														</option>
														{lists &&
															lists.map(
																(list, i) => (
																	<option
																		key={i}
																		value={list.id}
																		selected={list.id === contact.lists.data[0].id}
																	>
																		{list.attributes.listName}
																		
																	</option>
																)
															)}
													</Field>
													<ErrorMessage
														name="contactList"
														component="div"
														className="mb-1 text-brandred w-3/4 flex flex-row justify-start items-start"
													/>
													<button

														type="submit"
														disabled={
															isSubmitting
														}
														className="mt-2 w-3/4 items-center justify-center px-6 py-2 text-lg text-center font-bold border-0 rounded-none bg-brandorange text-white hover:underline"
													>
														Update Contact
													</button>
												</Form>
											)}
										</Formik>
										<p className="text-sm text-brandred">
											{errMsg}
										</p>
									</div>
								</div>
							</div>
							<div className="mt-2 sm:mt-2">
								<button
									type="button"
									className="inline-flex justify-center w-full shadow-sm px-4 py-2 text-xl uppercase font-bold text-brandteal hover:text-brandblack focus:outline-none sm:text-sm"
									onClick={() => setOpenEdit(false)}
									ref={cancelButtonRef}
								>
									No, Cancel
								</button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default EditContact;
