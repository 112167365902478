import React from "react";
import {useLocation} from 'react-router-dom'

import Chat from "../../components/app/Chat";
import ConversationList from "../../components/app/conversation/ConversationList";
import UserStats from "../../components/app/conversation/UserStats";
import { Seo } from '../../utils/Seo';


const Conversation = () => {
	
	let location = useLocation();
	const conversationId = location.state.id;
	const SGID = location.state.SGID;
	const username = location.state.username;
	// console.log(conversationId, SGID, username)

	return (
		<div className="lg:relative lg:max-w-full">
		{Seo('Conversation')}
			<div className="mx-20 my-10">
				<div className="m-6 items-center justify-between grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 md:gap-4 lg:grid lg:grid-cols-3 lg:gap-4">
					<div className="bg-white overflow-hidden shadow rounded-md border-brandblack border-2 h-[40rem] mx-auto lg:mx-0">
						<ConversationList selectedUserId={SGID}/>
					</div>
					<div
						className={`flex flex-col bg-white overflow-hidden shadow rounded-md border-brandblack border-2 h-[40rem] mx-auto lg:mx-0`}
					>
						<Chat conversationId={conversationId} />
					</div>
					<div
						className={`flex flex-col bg-white overflow-hidden shadow rounded-md border-brandblack border-2 h-[40rem] mx-auto lg:mx-0`}
					>
						<UserStats SGID={SGID} username={username} conversationId={conversationId} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Conversation;
