import { PlusCircleIcon, XIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import ListItem from "../ListItem";
import Contacts from "./Contacts";
import ReactTooltip from "react-tooltip";
import AddList from "./Lists/AddList";
import DeleteList from "./Lists/DeleteList";

const ContactsList = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [activeItem, setActiveItem] = useState("All");
  const [listValue, setListValue] = useState("All");
  const [open, setOpen] = useState(false);

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [list, setList] = useState(null);

  const handleContactDeleteConfirmation = (list) => {
    setDeleteOpen(true);
    setList(list);
  };

  const handleClick = (item, value) => {
    setActiveItem(item);
    setListValue(value);
  };

  return (
    <>
      <div className="px-4 py-5 sm:px-6 border-b-2 border-brandblack uppercase text-brandblack text-base font-bold leading-5 tracking-widest">
        Contacts | <span className="font-normal text-xs">{activeItem}</span>
      </div>
      <div className="flex h-full">
        <div className="flex flex-col w-[10rem] h-[28rem] scrollbar-hide justify-start border-r-2 border-brandblack pt-3 overflow-ellipsis overflow-y-scroll">
          {user.lists.map((list) => (
            <div className="flex" key={list.id}>
              <div className="flex-auto w-3/4" onClick={() => handleClick(list.listName, list.id)}>
                <ListItem active={activeItem} listItem={list.listName} />
              </div>
              <div className="flex-auto w-1/4">
                <ReactTooltip
                  border
                  borderColor="rgb(62 82 97)"
                  backgroundColor="#FFF"
                  textColor="text-brandBlack"
                />
                <XIcon
                  className="h-[1.25rem] w-[1.25rem] hover:text-brandteal hover:cursor-pointer text-brandorange my-2 font-normal items-start pl-2"
                  data-tip="Delete List"
                  onClick={() => handleContactDeleteConfirmation(list)}
                />
              </div>
            </div>
          ))}
          <div onClick={() => handleClick("All", "All")}>
            <ListItem active={activeItem} listItem={"All"} />
          </div>
          <div>
            <ReactTooltip
              border
              borderColor="rgb(62 82 97)"
              backgroundColor="#FFF"
              textColor="text-brandBlack"
            />
            <PlusCircleIcon
              data-tip="Add New List"
              onClick={() => setOpen(true)}
              className="hover:text-brandblack hover:cursor-pointer text-brandteal mr-4 h-[1.5rem] w-[1.5rem]"
            />
          </div>
        </div>
        <div className="flex flex-col overflow-y-scroll w-full h-[28rem] scrollbar-hide">
          <Contacts listValue={listValue} />
        </div>
      </div>
      <AddList setOpen={setOpen} open={open} lists={user.lists} />
      <DeleteList deleteOpen={deleteOpen} setDeleteOpen={setDeleteOpen} list={list} />
    </>
  );
};

export default ContactsList;