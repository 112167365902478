import React, { useState, useEffect } from "react";
import { UserCircleIcon } from "@heroicons/react/solid";
import { PlusCircleIcon } from "@heroicons/react/outline";
import { trackPromise } from "react-promise-tracker";
import fb from "../../../api/fb";
import {
	differenceInCalendarDays,
	differenceInMinutes,
	differenceInHours,
} from "date-fns";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AddContact from "../dashboard/Contacts/AddContact";
import ReAuth from "../ReAuth";
import { LogError } from "../../../utils/LogError";

const MessageList = ({ selectedUserId }) => {
	let navigate = useNavigate();
	const entity = JSON.parse(sessionStorage.getItem("entity"));
	const userInfo = sessionStorage.getItem('user');
	const FBPageAccess = sessionStorage.getItem("FBPageAccess");
	const FBPageId = entity.fbApiKey;
	const [conversation, setConversation] = useState([]);
	const [open, setOpen] = useState(false);
	const [openAuth, setOpenAuth] = useState(false);
	const [addIgHandle, setAddIgHandle] = useState(null);
	const [addScopeId, setAddScopeId] = useState(null);
	const newMessage = useSelector((state) => state.socket.socket);

	// get Insta Converstations on load
	useEffect(() => {
		trackPromise(
			fb
				.get(`/${FBPageId}/conversations`, {
					params: {
						platform: "instagram",
						access_token: FBPageAccess,
					},
				})
				.then((response) => {
					var convos = response.data.data;

					convos.map((singleConvo) => {
						fb.get(`/${singleConvo.id}`, {
							params: {
								fields: "messages,participants,updated_time",
								access_token: FBPageAccess,
							},
						}).then((response2) => {
							var convo = response2.data;

							//get first message from each conversation
							var lastestMessage =
								response2.data.messages.data[0];
							fb.get(`/${lastestMessage.id}`, {
								params: {
									fields: "id,from,to,message,story",
									access_token: FBPageAccess,
								},
							})
								.then((response3) => {
									convo["latestMessage"] =
										response3.data.message;
									return setConversation((conversations) => [
										...conversations,
										convo,
									]);
								})
								.catch((error) => {
									sessionStorage.removeItem("fbSession");
									setOpenAuth(true);
									LogError("Error Getting Latest Message", error);

								});
						});
						return null;
					});
				})
				.catch((error) => {
					LogError("Error Getting Conversations", error);
					sessionStorage.removeItem("fbSession");
					setOpenAuth(true);
				})
		);
	}, [FBPageAccess, FBPageId]);

	const dateDiff = (timestamp) => {
		const current = new Date();
		const inputDate = new Date(timestamp);

		var minutes = differenceInMinutes(current, inputDate);
		var hours = differenceInHours(current, inputDate);
		var days = differenceInCalendarDays(current, inputDate);

		if (minutes < 60) {
			return `${minutes}m`;
		}
		if (hours < 24) {
			return `${hours}h`;
		} else {
			return `${days}d`;
		}
	};

	const handleConversation = (id, SGID, username) => {
		navigate(`/app/conversation`, { state: { id: id, SGID: SGID, username: username } });
	};

	return (
		<div>
			<ul className="divide-y divide-gray-200">
				{conversation.map((convo) => (
					<li
						className={`py-4 px-2 hover:bg-brandeggshell ${convo.participants.data[1].id === selectedUserId ? 'bg-brandeggshell' : ''}`}
						key={convo.id}
						onClick={() => handleConversation(convo.id, convo.participants.data[1].id, convo.participants.data[1].username)}
					>
						<div className="flex space-x-3 items-center cursor-pointer">
							<div className="relative">
								<UserCircleIcon className="h-8 w-8 rounded-full" />
							</div>
							<div className="flex-1 space-y-1">
								<div className="flex-col justify-between items-center gap-3">
									<div className="text-sm font-normal text-brandblack">
										{convo.participants.data[1].username}
									</div>
								</div>
								<div className="flex justify-between">
									<div className="flex justify-between text-sm text-brandgray">
										<span className="">
											{newMessage?.senderId === convo.participants.data[1].id ?
												newMessage?.message?.length > 10 ?
													`${newMessage?.message.slice(0, 10)}...`
													: newMessage?.message
												:
												convo.latestMessage.length > 10
													? `${convo.latestMessage.slice(
														0,
														10
													)}...`
													: convo.latestMessage
											}
											{convo.latestMessage === "" ? "Story Mention" : ""}
										</span>
										<span className="ml-4">
											{newMessage?.senderId === convo.participants.data[1].id ?
												<span className="text-brandblack">NEW</span>
												:
												dateDiff(convo.updated_time)
											}
										</span>
									</div>
								</div>
							</div>
							<div>
								<PlusCircleIcon
									onClick={() => {
										setOpen(true);
										setAddIgHandle(
											convo.participants.data[1].username
										);
										setAddScopeId(
											convo.participants.data[1].id
										);
									}}
									className="hover:text-brandblack hover:cursor-pointer text-brandteal mr-4 h-[1.5rem] w-[1.5rem]"
								/>
							</div>
						</div>
					</li>
				))}
			</ul>
			{/* MODAL CONTENT */}
			<AddContact
				open={open}
				setOpen={setOpen}
				igHandle={addIgHandle}
				scopeId={addScopeId}
				entity={entity.id}
				owner={userInfo.id}
			/>
			<ReAuth openAuth={openAuth} setOpenAuth={setOpenAuth} />
		</div>
	);
};

export default MessageList;
