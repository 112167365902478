import React from "react";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";

const SiteLayout = () => {
	return (
		<div className="App flex flex-col h-screen">
			<Header />
			<Outlet />
			<Footer />
		</div>
	);
};

export default SiteLayout;
