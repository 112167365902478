import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import fb from "../../../api/fb";
import moment from 'moment';
import { LogError } from "../../../utils/LogError";

const FollowerChart = () => {

	const [grahpData, setGraphData] = useState([]);
	const [metric, setMetric] = useState({value: 'profile_views', name: 'Profile Views'});
	//follower_count,impressions,reach,profile_views

	const entitySGID = JSON.parse(sessionStorage.getItem("entity")).sgId;
	let now = new Date();
	const backdate = new Date(now.setDate(now.getDate() - 29));
	const timestampInSeconds = Math.floor(backdate.getTime() / 1000);
	const [FBSession, setFBSession] = useState(
		sessionStorage.getItem("fbSession")
	);

	const getInsightData = async () => {
		let data = [];
		if (entitySGID) {
			try {
				const response = await fb.get(`/${entitySGID}/insights`, {
					params: {
						access_token: FBSession,
						metric: metric.value,
						period: 'day',
						since: timestampInSeconds

					},
				});
				if (response?.data?.data) {
					response.data.data[0].values.map((dataPoint) => {
						var newDate = moment(dataPoint.end_time).format('MM/DD/YYYY');
						data.push({ x: newDate, y: dataPoint.value });
					});
				}
				setGraphData(data);
			} catch (err) {
				LogError("Error Occurred, Getting Insight Data", err);
				console.error(err);
			}
		}
	};

	useEffect(() => {
		getInsightData();
	}, [metric]);
	return (
		<div className="px-4 py-5 sm:p-6 w-full h-auto">
			<h2 className="pb-2 uppercase text-brandblack text-base font-bold leading-5 tracking-widest"><span className="text-brandorange">{metric.name}</span> Last 30 Days</h2>
			<div className="flex">
				<Line
					data={{
						datasets: [
							{
								type: "line",
								data: grahpData,
								backgroundColor: "transparent",
								borderColor: "#F89767",
								borderWidth: 2,
								pointBorderColor: "rgba(255,255,255,0)",
								pointBackgroundColor: "rgba(255,255,255,0)",
								pointHoverBackgroundColor: "#F89767",
								pointHoverBorderColor: "#000",
								pointHoverBorderWidth: 4,
								pointHoverRadius: 6,
							},
						],
					}}
					options={{
						maintainAspectRatio: false,
						responsive: true,
						layout: {
							padding: {
								top: 10,
								bottom: 10
							}
						},
						legend: {
							display: false,
						},
						tooltips: {
							mode: "index",
							intersect: false,
						},
						scales: {
							xAxes: [
								{
									gridLines: "transparent",
									type: "time",
									ticks: {
										beginAtZero: true,
										display: true,
										padding: 20
									},
								},
							],
							yAxes: [
								{
									gridLines: "transparent",
									ticks: {
										beginAtZero: false,
										display: true,
										padding: 20
									},
								},
							],
						},
					}}
				/>
			</div>
			<div className="grid grid-cols-4 gap-4">
				<button
					onClick={() => setMetric({value: 'follower_count', name:'Follower Count'})}
					className={`text-sm text-center font-medium rounded-none border border-brandorange  hover:bg-brandorange ${metric.value === 'follower_count' ? 'text-white bg-brandorange' : 'bg-white text-brandorange'}`}
				>
					Follower Count
				</button>
				<button
					onClick={() => setMetric({value: 'impressions', name:'Impressions'})}
					className={`text-sm text-center font-medium rounded-none border border-brandorange  hover:bg-brandorange ${metric.value === 'impressions' ? 'text-white bg-brandorange' : 'bg-white text-brandorange'}`}
				>
					Impressions
				</button>
				<button
					onClick={() => setMetric({value: 'reach', name:'Reach'})}
					className={`text-sm text-center font-medium rounded-none border border-brandorange  hover:bg-brandorange ${metric.value === 'reach' ? 'text-white bg-brandorange' : 'bg-white text-brandorange'}`}
				>
					Reach
				</button>
				<button
					onClick={() => setMetric({value: 'profile_views', name:'Profile Views'})}
					className={`text-sm text-center font-medium rounded-none border border-brandorange  hover:bg-brandorange ${metric.value === 'profile_views' ? 'text-white bg-brandorange' : 'bg-white text-brandorange'}`}
				>
					Profile Views
				</button>
			</div>
		</div>
	);
};

export default FollowerChart;
