import { useState, useRef, useEffect } from "react";
import { PlusCircleIcon, XIcon, PencilAltIcon } from "@heroicons/react/outline";
import ReactTooltip from "react-tooltip";
import { trackPromise } from "react-promise-tracker";
import axios from "../../../api/axios"
import AddTask from "./AddTask";
import { LogError } from "../../../utils/LogError";

function Tasks({ contactId, tasks }) {
	const userSession = sessionStorage.getItem("userSession");
	const [open, setOpen] = useState(false);
	const [addEdit, setAddEdit] = useState(true);
	const [selectedTask, setSelectedTask] = useState(false);
	const errRef = useRef();
	const [errMsg, setErrMsg] = useState("");
	const [tasksState, setTasksState] = useState(null);

  useEffect(() => {
    setTasksState(tasks)
  }, [tasks])

	const handleTaskDelete = (id) => {
		//filter out note based on id
		const remaining = tasksState.filter((task) => task._id !== id);
		//api PUT on contacts, same as add Note

		const data = {
			tasks: remaining,
		};

		trackPromise(
			axios
				.put(`/api/contacts/${contactId}`,
					{ data: data },
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${userSession}`,
						},
					}
				)
				.then((response) => {
					// console.log(response)
					setTasksState(response.data.data.attributes.tasks)
					// window.location.reload(false);
				})
				.catch((error) => {
					console.log(error);
					LogError("Error deleting task", error);
					setErrMsg("Error deleting task, Please try again");
					errRef.current.focus();
				})
		);
	};
	
	const handleEditTask = (task) => {
		setAddEdit(false);
		setSelectedTask(task);
		setOpen(true);
		
	}
	
	const handleClickAddTask = () => {
		setAddEdit(true);
		setSelectedTask(false);
		setOpen(true);
	};

	return (
		<div className="bg-white overflow-hidden shadow rounded-md border-brandblack border-2 h-[10rem] mx-auto lg:mx-0">
			<div className="flex justify-between px-3 py-3 sm:px-3 border-b-2 border-brandblack uppercase text-brandblack text-base font-medium leading-5 tracking-widest">
				<>Tasks</>

				<>
					<ReactTooltip border={true} borderColor={'rgb(62 82 97)'} backgroundColor={'#FFF'} textColor={'text-brandBlack'}  />
					<PlusCircleIcon
						data-tip="Add Task"
						onClick={() => {
							handleClickAddTask()
						}}
						className="hover:text-brandblack hover:cursor-pointer text-brandteal h-[1.5rem] w-[1.5rem]"
					/>
				</>
			</div>
			<ul className="px-4 text-sm text-brandblack w-full overflow-y-scroll h-[6.25rem] scrollbar-hide">
				{tasksState &&
					tasksState.map((task) => (
						<li
							className="flex justify-between items-center border-y-[1px] py-3"
							key={task._id}
						>
							<div className="flex justify-start">
								<input type="checkbox" />
								<p className="ml-4">{task.task}</p>
								<p className="ml-4">Due: {new Date(task.dueDate).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"}) }</p>
							</div>
							<>
								<PencilAltIcon className="h-[1.25rem] w-[1.25rem] hover:text-brandteal hover:cursor-pointer text-brandorange" onClick={() => handleEditTask(task)} />
								<XIcon className="h-[1.25rem] w-[1.25rem] hover:text-brandteal hover:cursor-pointer text-brandorange" onClick={() => handleTaskDelete(task._id)} />
							</>
						</li>
					))}
				{(!tasksState || tasksState.length === 0) && <p>No tasks to display</p>}
				<p className="text-sm text-brandred">
					{errMsg}
				</p>
			</ul>
			<AddTask
				open={open}
				setOpen={setOpen}
				contactId={contactId}
				tasks={tasks}
				editTask={selectedTask}
				addEdit={addEdit}
				tasksState={tasksState}
				setTasksState={setTasksState}
			/>
		</div>
	);
}

export default Tasks;
