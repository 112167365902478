import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { trackPromise } from "react-promise-tracker";
import axios from "../../../api/axios";
import { LogError } from "../../../utils/LogError";


const DeleteContact = ({ open, setOpen, contactId }) => {
	const userSession = sessionStorage.getItem("userSession");
	const cancelButtonRef = useRef(null);
	const errRef = useRef();
	const [errMsg, setErrMsg] = useState("");

	const handleCancel = () => {
		setOpen(false);
	}

	const handleDeleteContact = () => {
		trackPromise(
			axios
				.delete(`/api/contacts/${contactId}`,
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${userSession}`,
						},
					}
				)
				.then((response) => {
					window.location.reload(false);
				})
				.catch((error) => {
					// console.log(error);
					// setErrMsg("Error adding Contact, Please try again");
					LogError("Error Occurred, Deleting Contact", error);
					errRef.current.focus();
				})
		);
	};

	return (
		<Transition.Root
			show={open}
			as={Fragment}

		>
			<Dialog as="div" className="fixed z-10 inset-0" initialFocus={cancelButtonRef} onClose={() => setOpen}>
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true"
					>
						&#8203;
					</span>
					<Transition.Child

						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
							<div>
								<div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
									<XIcon
										className="h-6 w-6 text-brandred"
										aria-hidden="true"
									/>
								</div>
								<div className="mt-3 text-center sm:mt-5">
									<Dialog.Title
										as="h2"
										className="text-lg leading-6 font-medium text-gray-900"
									>
										Are you sure you want to remove this contact?

									</Dialog.Title>
									<p className="mt-3 text-center sm:mt-5">This will not remove the contact from your conversation list</p>
								</div>
							</div>

							<div className="mt-2 sm:mt-2">
								<button

									type="submit"
									className="mt-2 w-full items-center justify-center px-6 py-2 text-lg text-center font-bold border-0 rounded-none bg-brandred text-white hover:underline"
									onClick={() => handleDeleteContact()}
								>
									Yes, Delete Contact
								</button>
							</div>
							<div className="mt-2 sm:mt-2">
								<button
									type="button"
									className="inline-flex justify-center w-full shadow-sm px-4 py-2 text-xl uppercase font-bold text-brandteal hover:text-brandblack focus:outline-none sm:text-sm"
									onClick={() => handleCancel()}
									ref={cancelButtonRef}
								>
									No, Cancel
								</button>
							</div>
							<p className="text-sm text-brandred">
								{errMsg}
							</p>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default DeleteContact;
