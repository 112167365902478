import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { trackPromise } from "react-promise-tracker";
import axios from "../../../api/axios";
import { v4 as uuidv4 } from "uuid";
import { Wysimark, useEditor } from "@wysimark/react";
import { useCallback } from "react";
import { LogError } from "../../../utils/LogError";



const NoteForm = ({ open, setOpen, contactId, notesState, setNotesState, note, handleUpdateNotes }) => {
	// console.log(note);
	const userSession = sessionStorage.getItem("userSession");
	const cancelButtonRef = useRef(null);
	const errRef = useRef();
	const [errMsg, setErrMsg] = useState("");
	var editor = useEditor({
		initialMarkdown: note?.note,
	})
	
	const onClick = useCallback(() => alert(editor.getMarkdown()), []);

	const handleSubmit = (values, { setSubmitting }) => {
		values.note = values.note.trim();

		const updatedNote = {
			_id: note?._id || uuidv4(),
			note: editor.getMarkdown(),
			created_at: note?.created_at || new Date(),
		};

		let updatedNotes;

		if (note) {
			updatedNotes = notesState.map((n) => (n._id === note._id ? updatedNote : n));
		} else {
			updatedNotes = [...notesState, updatedNote];
		}

		const data = {
			notes: updatedNotes,
		};

		trackPromise(
			axios
				.put(`/api/contacts/${contactId}`, { data: data }, {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${userSession}`,
					},
				})
				.then((response) => {
					handleUpdateNotes(response.data.data.attributes.notes);
					setSubmitting(false);
		setOpen(false);
				})
				.catch((error) => {
					LogError("Error adding note", error);
					console.log(error);
					setErrMsg("Error Occured, Please try again");
					errRef.current.focus();
				})
		);

		
		
	};


	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				className="fixed z-10 inset-0"
				initialFocus={cancelButtonRef}
				onClose={() => setOpen}
			>
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true"
					>
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
							<div>
								<div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
									<CheckIcon
										className="h-6 w-6 text-green-600"
										aria-hidden="true"
									/>
								</div>
								<div className="mt-3 sm:mt-5">
									<Dialog.Title
										as="h3"
										className="text-lg leading-6 font-medium text-gray-900 text-center"
									>
										{note ? "Edit Note" : "Add Note"}
									</Dialog.Title>
									<div className="mt-2">
										<Formik
											initialValues={{
												note: note?.note || "",
											}}
											validate={(values) => {
												const errors = {};
												if (!editor.getMarkdown()) {
													errors.note = "This field is Required";
												}

												return errors;
											}}
											onSubmit={handleSubmit}
										>
											{({ isSubmitting }) => (
												<Form className="flex flex-col justify-center items-center mt-6">
											
													<Wysimark name="editor" editor={editor} />
													<ErrorMessage
														name="note"
														component="div"
														className="mb-1 text-brandred w-3/4 flex flex-row justify-start items-start"
													/>

													<button
														type="submit"
														disabled={isSubmitting}
														className="mt-2 w-3/4 items-center justify-center px-6 py-2 text-lg text-center font-bold border-0 rounded-none bg-brandorange text-white hover:underline"
													>
														{note ? "Save Changes" : "Add Note"}
													</button>
												</Form>
											)}
										</Formik>
										<p className="text-sm text-brandred">{errMsg}</p>
									</div>
								</div>
							</div>
							<div className="mt-2 sm:mt-2">
								<button
									type="button"
									className="inline-flex justify-center w-full shadow-sm px-4 py-2 text-xl uppercase font-bold text-brandteal hover:text-brandblack focus:outline-none sm:text-sm"
									onClick={() => setOpen(false)}
									ref={cancelButtonRef}
								>
									No, Cancel
								</button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default NoteForm;
