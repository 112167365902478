import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const entitySlice = createSlice({
	name: "entity",
	initialState,
	reducers: {
		setEntity: (state, action) => {
			state.entity = action.payload;
            sessionStorage.setItem('entity', JSON.stringify(action.payload))
		},
        updateEntity: (state, action) => {
            state.entity = {...state.entity, ...action.payload}
        },

		removeEntity: (state) => {
			state.entity = {};
		},
	},
});

export const { setEntity, removeEntity, updateEntity } = entitySlice.actions;
export default entitySlice.reducer;
