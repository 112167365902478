import React from "react";
import { Link } from "react-router-dom";

const OneCol = ({ content, button }) => {
	return (
		<section>
			<div className={`pb-8 sm:pb-12 lg:pb-12 ${content.bgColor} ${button ? 'top-slant' : 'slant-blue'}`}>
				<div className="pt-8 text-center overflow-hidden sm:pt-12 lg:relative lg:py-36">
					<div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
						<div className="flex flex-col h-full justify-center items-center">
							<div className="sm:max-w-xl">
								<div className={`mb-8 uppercase text-base font-medium ${content.textColor}`}>
									{content.superText}
								</div>
								<h1 className={`text-4xl font-bold ${content.textColor} tracking-tight sm:text-5xl lg:text-4xl`}>
									{content.header}
								</h1>
								<p className={`mt-10 text-base ${content.textColor} leading-6`}>
									{content.subtext}
								</p>
							</div>
							{button ? (
								<Link
									to="/"
									className="bg-brandred text-white text-center font-semibold text-sm px-28 py-2 hover:bg-brandorange"
								>
									View Demo
								</Link>
							) : (
								<></>
							)}
						</div>

						<div className="mt-10 relative w-full h-auto sm:h-72 md:h-96 lg: lg:w-auto lg:h-auto">
							<img
								className={`relative w-full mx-auto inset-0 lg:${content.imageSize} h-auto object-contain`}
								src={content.image}
								alt=""
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default OneCol;
