import axios from '../api/axios';
import { toast } from 'react-toastify';

async function LogError(errorMessage, errorDetails) {
    const currentPagePath = window.location.pathname;
    const userSession = sessionStorage.getItem("userSession");
   
	const user = JSON.parse(sessionStorage.getItem("user"))?? null;
    const entity = sessionStorage.getItem("fbSession")?? null;
    toast.error(errorMessage);
    const errorDetailsToSend = {
        message: errorDetails.message,
        name: errorDetails.name,
        stack: errorDetails.stack,
    };

  const logData = {
    message: errorMessage ?? null,
    error: JSON.stringify(errorDetailsToSend),
    userInformation: {
      id: user?.id ?? null,
      username: user?.username ?? null,
      email: user?.email ?? null,
    },
    browserDetails: {
      userAgent: navigator.userAgent,
    },
    appState: {
        currentPagePath: currentPagePath,
    },
    // users_permissions_user: [user?.id] ?? null ,
    // entity: entity?.id ?? null,
  };

  try {
    await axios.post('/api/logs', 
    { data: logData }, 
    {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userSession}`,
        },
    } );
  } catch (error) {
    console.error('Failed to send log data to Backend:', error);
  }
}
export { LogError };
