import React, { useState } from "react";

import ListItem from "../../app/ListItem";
import MessageList from "./MessageList";

const ConversationList = () => {
	const entity = JSON.parse(sessionStorage.getItem('entity'));
	const [activeItem, setActiveItem] = useState('Newest First');
	var filters = [
		{id:'newestFirst', name:'Newest First'},
		{id:'oldestFirst', name:'Oldest First'},
		{id:'alphabetical', name:'Alphabetical'}

	]

	return (
		<>
			<div className="px-4 py-5 sm:px-6 border-b-2 border-brandblack uppercase text-brandblack text-base font-bold leading-5 tracking-widest">
				{/* Content goes here */}
				Conversations
			</div>

			{/* Content goes here */}
			<div className="flex h-full">
				<div
					className={`flex flex-col w-[10rem] h-[28rem] scrollbar-hide justify-start border-r-2 border-brandblack pt-3 pr-4 overflow-ellipsis overflow-y-scroll`}
				>
					{filters.map((filter) => (
						<div
							key={filter.id}
							onClick={() => {
								setActiveItem(filter.name);
							}}
						>
							<ListItem
								active={activeItem}
								listItem={filter.name}
							/>
						</div>
					))}
				</div>
				<div
					className={`flex flex-col overflow-y-scroll w-full h-[28rem]`}
				>
					<MessageList activeItem={activeItem}/>
				</div>
			</div>
		</>
	);
};

export default ConversationList;
