import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as TomLogo } from '../../assets/tom-logo.svg'


function Footer() {
	const today = new Date();
	return (
		<footer>
			<div className="pt-8 pb-8 text-center overflow-hidden sm:pt-12 lg:relative lg:py-28 bg-brandblack">
				<div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl">
					<div className="flex flex-col lg:flex-row justify-between items-center">
						<Link
							to="/signup"
							className="my-2 lg:my-0 uppercase whitespace-nowrap items-center justify-center px-10 py-2 border border-brandpink rounded-none shadow-sm text-base font-medium text-brandpink bg-transparent hover:underline"
						>
							Sign up
						</Link>
						<Link className="my-2 lg:my-0" to="/">
							<div className="flex flex-col items-center justify-center">
								<TomLogo
									className="w-3/5 h-auto fill-brandpink"
								/>
								<div className="relative text-brandpink text-3xl mt-3">
									<span className="font-semibold">Top</span>{" "}
									of{" "}
									<span className="font-semibold">Mind</span>
								</div>
							</div>
						</Link>
						<Link
							to="/login"
							className="my-2 lg:my-0 uppercase whitespace-nowrap items-center justify-center px-10 py-2 border border-brandpink rounded-none shadow-sm text-base font-medium text-brandpink bg-transparent hover:underline"
						>
							Login
						</Link>
					</div>
					<div className="flex flex-col lg:flex-row justify-between items-center mt-16 text-xs text-white text-center">
						<div>
							Copyright BCV, LLC &copy; {today.getFullYear()}
						</div>
						<Link to="/terms-and-services" className="lg:mr-12">
							Terms &amp; Services
						</Link>
						<Link to="/privacy-policy">
							Privacy Policy
						</Link>
					</div>
					
				</div>
			</div>
		</footer>
	);
}

export default Footer;
