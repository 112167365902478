import React from "react";
import { Link } from "react-router-dom";

var data = {
	superText: "A CRM for your DMS",
	header: "Manage your social media empire",
	subtext:
		"Put an end to the endless unanswered, unmanaged, and full direct message (DM) inboxes that waste your time and lose you business.",
	subHeader:
		"It’s time to finally take control of your messaging.",
	image: "",
};

const Hero = () => {
	return (
		<section>
			<div className="bg-brandwhite pb-8 sm:pb-12 lg:pb-12">
				<div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-36">
					<div className="relative text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 md:text-center lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24 lg:text-left">
						<div>
							<div className="mt-0 mx-auto">
								<div className="uppercase text-base font-medium text-brandblack">
									{data.superText}
								</div>
								<div className="mt-8 sm:max-w-xl mx-auto">
									<h1 className="text-4xl font-bold text-brandblack tracking-tight sm:text-5xl lg:text-6xl lg:w-3/4">
										{data.header}
									</h1>
									<p className="mt-6 text-base text-brandblack leading-6 lg:w-1/2">
										{data.subtext}
									</p>
									<p className="mt-6 text-base text-brandblack leading-6 lg:w-1/2 font-bold">
										{data.subHeader}
									</p>
								</div>

								<div className="mt-6 lg:w-1/2">
									<div className="flex flex-col z-10">
										<Link
											to="signup"
											className="z-10 mt-4 flex-1 items-center justify-center px-6 py-2 text-sm text-center font-medium border-0 rounded-none bg-brandorange text-white hover:underline"
										>
											Sign up
										</Link>
										<Link
											to="login"
											className="z-10 mt-4 flex-1 items-center justify-center px-6 py-2 text-sm text-center border border-brandorange rounded-none font-normal text-brandorange bg-transparent hover:underline"
										>
											Log in
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="relative w-full h-auto sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:left-1/2 lg:top-0 lg:w-full lg:h-full">
							<img
								className="relative mt-10 lg:w-1/2 h-full w-full object-contain lg:mt-0"
								src="/phones/hero.png"
								alt=""
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Hero;
