import Lead from "./Lead";
import Notes from "./Notes";
import Tasks from "./Tasks";
import Calendar from "./Calendar";
import { PencilAltIcon } from '@heroicons/react/outline';
import EditContact from '../dashboard/Contacts/EditContact';
import { useState } from "react";

const ContactUtil = ({ currentContact, instaDetails }) => {
	const [openEdit, setOpenEdit] = useState(false)
	
	const handleEditContact = (contact) => {
		setOpenEdit(true)
	};

	return (
		<>
			<div className="px-4 py-5 sm:px-6 border-b-2 border-brandblack uppercase text-brandblack text-base font-medium leading-5 tracking-widest flex">
				<div className="flex-1">Contact |
					{currentContact.lists?.data.map((list,i) => (
						<span className="text-sm text-brandblack ml-4 font-normal capitalize">{list.attributes.listName}</span>
					))}
					</div>
					<div className="shrink text-right"><PencilAltIcon className="h-[1.25rem] w-[1.25rem] hover:text-brandteal hover:cursor-pointer text-brandorange" onClick={() => handleEditContact(currentContact)} /></div>
			</div>
			<div className="p-4 py-2 sm:p-6 h-full lg:grid lg:grid-cols-2 lg:gap-4">
				{/* Content goes here */}
				<div className="flex flex-col">
					<div className="mb-8">
						<div className="flex flex-row items-start">
							<div className="flex flex-col items-center">
								{instaDetails.profile_pic && (
									<img
										src={instaDetails.profile_pic}
										alt=""
										className="w-1/2 h-auto rounded-full"
									/>
								)}
								{!instaDetails.profile_pic && (
									<img
										src="\people\default-user.png"
										alt=""
										className="w-1/2 h-auto rounded-full"
									/>
								)}
							</div>
							<div className="flex flex-col items-start ">
								<div className="pb-2 capitalize text-brandblack text-sm font-normal">
									{currentContact.firstName}{" "}
									{currentContact.lastName}{" "}
									<a
										className="hover:text-brandteal"
										href={`https://www.instagram.com/${currentContact.instagramHandle}/`}
										target={"_blank"}
										rel="noreferrer"
									>
										@{currentContact.instagramHandle}
									</a>
								</div>
								<div className="py-2 text-brandteal text-sm font-light">
									{instaDetails?.follower_count} Followers
								</div>
								<div className="py-2 text-brandteal text-sm font-light">
									Following:{" "}
									{instaDetails?.is_business_follow_user ===
									true
										? "Yes"
										: "No"}
								</div>
								<div className="py-2 text-brandteal text-sm font-light">
									Following You:{" "}
									{instaDetails?.is_user_follow_business ===
									true
										? "Yes"
										: "No"}
								</div>
							</div>
						</div>
					</div>
					<div className="mb-4">
						<Notes
							contactId={currentContact.id}
							notes={currentContact.notes}
						/>
					</div>
					<div className="mb-4">
						<Tasks
							contactId={currentContact.id}
							tasks={currentContact.tasks}
						/>
					</div>
				</div>
				<div className="flex flex-col">
					<div className="mb-4 flex justify-center items-center">
						<Calendar tasks={currentContact.tasks} contactId={currentContact.id} />
					</div>
					{/* <Lead /> */}
					{/* lead card */}
				</div>
			</div>
			{openEdit && <EditContact openEdit={openEdit} setOpenEdit={setOpenEdit} contact={currentContact} />}

		</>
	);
};

export default ContactUtil;
