import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const contactsSlice = createSlice({
	name: "contacts",
	initialState,
	reducers: {
		setContacts: (state, action) => {
			state.contacts = action.payload;
            sessionStorage.setItem('contacts', JSON.stringify(action.payload))
		},
		
	},
});

export const { setContacts } = contactsSlice.actions;
export default contactsSlice.reducer;
