import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { setUser } from "../../features/user/userSlice";
import axios from "../../api/axios";
import { Seo } from "../../utils/Seo";

// Validation schema for form validation
const validationSchema = yup.object().shape({
  identifier: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
});

// Login form component
const LoginForm = ({ onSubmit, isSubmitting }) => (
  <Form className="flex flex-col justify-center items-center mt-6">
    <Field
      placeholder="Email Address"
      className="w-3/4 my-1.5 px-3 py-2 border border-brandblack rounded-none shadow-sm text-sm font-normal text-brandblack bg-transparent focus:outline-none"
      type="email"
      name="identifier"
    />
    <ErrorMessage
      name="identifier"
      component="div"
      className="mb-1 text-brandred w-3/4 flex flex-row justify-start items-start"
    />
    <Field
      placeholder="Password"
      className="w-3/4 my-1.5 px-3 py-2 border border-brandblack rounded-none shadow-sm text-sm font-normal text-brandblack bg-transparent focus:outline-none"
      type="password"
      name="password"
    />
    <ErrorMessage
      name="password"
      component="div"
      className="mb-1 text-brandred w-3/4 flex flex-row justify-start items-start"
    />
    <button
      type="submit"
      disabled={isSubmitting}
      className="mt-2 w-3/4 items-center justify-center px-6 py-2 text-sm text-center font-medium border-0 rounded-none bg-brandorange text-white hover:underline"
    >
      Submit
    </button>
  </Form>
);

const Login = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [errMsg, setErrMsg] = useState("");

  const handleLogin = async (data) => {
    try {
      const response = await axios.post("/api/auth/local", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      sessionStorage.setItem("userSession", response?.data?.jwt);
      dispatch(setUser(response?.data));
      navigate("/app/set-entity");
    } catch (err) {
      setErrMsg(
        err?.response?.data?.message[0]?.messages[0]?.message ||
          "Login Failed"
      );
    }
  };

	return (
		<>
			{Seo("Login")}

			<main className="flex-1 bg-brandwhite">
				<div className=" pb-8 sm:pb-12 lg:pb-12">
					<div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-36">
						<div className="flex justify-center items-center text-center w-full">
							<div className="flex flex-col h-full justify-center items-center">
								<div className="sm:max-w-xl">
									<div className="mb-8 uppercase text-base font-medium text-brandblack">
										Login
									</div>
									<h1 className="text-4xl font-bold text-brandblack tracking-tight sm:text-5xl lg:text-4xl">
										Your dashboard awaits
									</h1>
									<Formik
										initialValues={{
											identifier: "",
											password: "",
										}}
										validationSchema={validationSchema}
										onSubmit={(values, { setSubmitting }) => {
											setTimeout(() => {
												handleLogin(values);
												setSubmitting(false);
											}, 400);
										}}
									>
										{({ isSubmitting }) => (
											<LoginForm onSubmit={handleLogin} isSubmitting={isSubmitting} />
										)}
									</Formik>
									<p className="text-sm text-brandred">{errMsg}</p>
								</div>
								<div className="mt-8 w-1/2 hidden">
									<div className="text-xs text-brandblack">
										Sign up using
									</div>
									<div className="mt-4 flex justify-between w-full">
										<a href="/login">
											<img
												src="/icons/facebook-icon@2x.png"
												alt=""
												className="w-auto h-auto"
											/>
										</a>
										<a href="/login">
											<img
												src="/icons/twitter-icon@2x.png"
												alt=""
												className="w-auto h-auto"
											/>
										</a>
										<a href="/login">
											<img
												src="/icons/google-icon@2x.png"
												alt=""
												className="w-auto h-auto"
											/>
										</a>
									</div>
								</div>
								<div className="mt-8">
									<Link
										to="/signup"
										className="uppercase text-brandorange font-bold text-base hover:underline"
									>
										sign up
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
	);
};

export default Login;
