import React from "react";
import { Seo } from '../../utils/Seo';

const Terms = () => {
	return (
		<main className="pb-8 sm:pb-12 lg:pb-12 bg-white">
		{Seo("Terms of Service")}
			<div className="pt-8 text-left overflow-hidden sm:pt-12 lg:relative lg:py-36">
				<div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-20 lg:max-w-7xl">
					<h1 className="text-4xl text-center mb-8 font-bold text-brandblack tracking-tight sm:text-5xl lg:text-4xl">
						Terms &amp; Services
					</h1>
					<p>
					Effective January 12, 2023.  Thanks for using Top of Mind's software!  These Top of Mind Terms & Services (collectively, "Terms") lay out your rights and responsibilities as a customer of Top of Mind software.  Administrators at Top of Mind will be the parties in charge of establishing your Top of Mind account, facilitating proper usage of the platform, and receiving feedback on the features we should improve to enhance our proprietary customer resource management ("CRM") platform.  Once onboarded into the platform, and connected to Instagram, customers or users (collectively anyone that is not an "Administrator") are solely responsible for the messages, content, and outcome of all their messaging through Top of Mind.  Top of Mind takes no responsibility for customer or user activity beyond providing a more efficient process to manage Instagram direct messages ("DMs").  Please reach out to your Top of Mind Administrator for any inquiries.  By using our platform, Top of Mind, in any way, you explicitly accept these Terms and without exception indemnify Top of Mind in all situations, circumstances, or outcomes. 
					</p>
				</div>
			</div>
		</main>
	);
};

export default Terms;
