import React, { useState, useRef, useCallback, useEffect } from 'react';

import { Calendar as ReactCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file
import AddTask from './AddTask';


function Calendar({tasks, contactId}) {
    // console.log(tasks)
    const clickRef = useRef(null)
    const [open, setOpen] = useState(false);
	const [selectedTask, setSelectedTask] = useState(false);

  useEffect(() => {
    /**
     * What Is This?
     * This is to prevent a memory leak, in the off chance that you
     * teardown your interface prior to the timed method being called.
     */
    return () => {
      window.clearTimeout(clickRef?.current)
    }
  }, [])
  const onSelectEvent = useCallback((calEvent) => {
    /**
     * Here we are waiting 250 milliseconds (use what you want) prior to firing
     * our method. Why? Because both 'click' and 'doubleClick'
     * would fire, in the event of a 'doubleClick'. By doing
     * this, the 'click' handler is overridden by the 'doubleClick'
     * action.
     */
    window.clearTimeout(clickRef?.current)

    clickRef.current = window.setTimeout(() => {
      setSelectedTask(calEvent);
      setOpen(true);

    }, 250)
  }, [])
    var events = [];
    tasks?.map((task,i)=>{
        events.push({
            ...task,
            id: task._id,
            title: task.task,
            allDay: true,
            start: new Date(task.dueDate),
            end:  new Date(task.dueDate),
            complete: task.complete
        })

    });
    const localizer = momentLocalizer(moment) // or globalizeLocalizer

    return (
        <div style={{height: '500px', width:'100%'}}>
            <ReactCalendar
            views={['month']}
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      showAllEvents={true}
      onSelectEvent={onSelectEvent}
    />
    <AddTask
				open={open}
				setOpen={setOpen}
				contactId={contactId}
				tasks={tasks}
				editTask={selectedTask}
				addEdit={false}
			/>
        </div>
    )
}

export default Calendar