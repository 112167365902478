import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import axios from "axios";
import { Link } from "react-router-dom";
import { LogError } from "../../../utils/LogError";

function UserStats({ SGID, username, conversationId }) {
	const FBPageAccess = sessionStorage.getItem("FBPageAccess");
	const contacts = JSON.parse(sessionStorage.getItem("contacts"));
	const [instaDetails, setInstaDetails] = useState(null);

	var filteredArray = contacts.filter(function(item) {
		return conversationId.indexOf(item.conversationId) === 0
	  
	  });

	useEffect(() => {
		trackPromise(
			axios
				.get(
					`https://graph.facebook.com/v12.0/${SGID}?access_token=${FBPageAccess}&fields=name,profile_pic,follower_count,is_user_follow_business,is_business_follow_user`
				)
				.then((response) => {
					setInstaDetails({ ...response.data });
				})
				.catch((err) => {
					setInstaDetails(null)
					LogError("Error Occurred, Getting Profile Instagram Details", err);
					console.log(err);
				})
		);
	}, [SGID, FBPageAccess]);

	return (
		<>
			<div className="px-4 py-5 sm:px-6 border-b-2 border-brandblack uppercase text-brandblack text-base font-bold leading-5 tracking-widest">
				{/* Content goes here */}
				USER STATS
				{/* {console.log(instaDetails)} */}
			</div>
			<div className=" py-8 ">
				<div className="flex flex-row items-start">
					<div className="flex flex-col items-center">
						<img
							src={instaDetails?.profile_pic ? instaDetails?.profile_pic : '/people/default-user.png'}
							alt=""
							className="w-1/2 h-auto rounded-full"
						/>
					</div>
					<div className="flex flex-1 flex-col items-start ">
						<div className="pb-2 text-brandblack text-base font-normal">
							{instaDetails?.name} -{" "}
							<a
								href={`https://instagram.com/${username}`}
								target="_blank"
								rel="noreferrer"
							>
								{username}
							</a>
						</div>
						<div className="py-2 text-brandteal text-base font-light">
							{instaDetails?.follower_count}{" "}
							{instaDetails?.follower_count === 1
								? "Follower"
								: "Followers"}
						</div>
						<div className="py-2 text-brandteal text-base font-light">
							Following:{" "}
							{instaDetails?.is_business_follow_user === true
								? "Yes"
								: "No"}
						</div>
						<div className="py-2 text-brandteal text-base font-light">
							Following You:{" "}
							{instaDetails?.is_user_follow_business === true
								? "Yes"
								: "No"}
						</div>

						<div className="py-3 text-brandorange border-b border-brandorange leading-4 text-xs">
							<a href={`https://instagram.com/${username}`} target="_blank">View Instagram Profile</a>
						</div>
						{filteredArray.length>0 &&
						<div className="py-3 text-brandorange border-b border-brandorange leading-4 text-xs">
							<Link
										to={`/app/contact/${filteredArray[0].id}/${username}`}
									>View Contact Profile</Link>
						</div>
						}
					</div>
				</div>
			</div>
		</>
	);
}

export default UserStats;
