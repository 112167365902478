import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.user = action.payload.user;
            sessionStorage.setItem('user', JSON.stringify(action.payload.user))
		},
		removeUser: (state) => {
			state.user = {};
		},
        updateUser: (state, action) => {
            state.user = action.payload;
            sessionStorage.setItem('user', JSON.stringify(action.payload))
        }, 
	},
});

export const { setUser, removeUser, updateUser } = userSlice.actions;
export default userSlice.reducer;
