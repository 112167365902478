import { useState, useEffect, useRef } from "react";
import FacebookLogin from '@greatsumini/react-facebook-login';
import { useDispatch } from "react-redux";
import { updateUser } from "../../features/user/userSlice";
import { setEntity } from "../../features/entity/entitySlice";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import fb from "../../api/fb";
import axios from "../../api/axios";
import { Seo } from '../../utils/Seo';
import { LogError } from '../../utils/LogError';

function AddEntity() {
	const userSession = sessionStorage.getItem("userSession");
	const user = JSON.parse(sessionStorage.getItem("user"));
	let navigate = useNavigate();
	const dispatch = useDispatch();
	const [FBSession, setFBSession] = useState(
		sessionStorage.getItem("fbSession")
	);
	const [FBPages, setFBPages] = useState(false);
	const [instagramId, setInstagramId] = useState(null);
	const errRef = useRef();
	const [errMsg, setErrMsg] = useState("");
	
	// Log user in to fb set fb access token
	const logUserIntoFB = (response) => {
		sessionStorage.setItem("fbSession", response.accessToken);
		setFBSession(response.accessToken);
	};
	useEffect(() => {
		const getInstagramId = async () => {
			if (FBPages) {
				try {
					const response = await fb.get(`/${FBPages[0].id}`, {
						params: {
							access_token: FBSession,
							fields: "instagram_business_account",

						},
					});
					setInstagramId(response?.data?.instagram_business_account?.id)
				} catch (err) {
					console.error(err);
					LogError("Error Occurred, Could not get Instagram ID", err)
					errRef.current.focus();
				}
			}
		};
		getInstagramId();
	}, [FBPages]);

	// if fb access token get pages
	useEffect(() => {
		const getFBPages = async () => {
			if (FBSession) {
				try {
					const response = await fb.get("/me/accounts/", {
						params: {
							access_token: FBSession,
						},
					});
					setFBPages(response?.data?.data);
				} catch (err) {
					console.error(err);
					LogError("Error Occurred, Could not get Facebook Pages", err);
					errRef.current.focus();
				}
			}
		};
		getFBPages();
	}, [FBSession]);

	// get user details
	useEffect(() => {
		const getUserDetails = async () => {
			try {
				const response = await axios.get("/api/users/me/details", {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${userSession}`,
					},
				});
				dispatch(updateUser(response?.data));
			} catch (err) {
				LogError("Error Occurred, Could not get User Details", err);
				errRef.current.focus();
			}
		};

		getUserDetails();
	}, [userSession, dispatch]);

	// create entity
	const createEntity = async (form) => {
		var selectedPage = FBPages.filter((page) => {
			return page.id === form.page;
		});

		selectedPage = selectedPage[0];
		sessionStorage.setItem("FBPageAccess", selectedPage.access_token);
		sessionStorage.setItem("FBPageId", form.page);

		let entityData = {
			fbApiKey: form.page,
			entityName: form.entityName,
			instagramHandle: form.instagramHandle,
			email: user.email,
			sgId: instagramId,
		};

		try {
			const response = await axios.post(
				"/api/entities",
				{ data: entityData },
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${userSession}`,
					},
				}
			);
			dispatch(setEntity(response?.data));
			navigate("/app");
		} catch (err) {
			console.error(err.response);
			if (err.response?.status === 400) {
				setErrMsg("An Entity with this name already exists");
				LogError("Error Occurred, An Entity with this name already exists", err);
			} else {
				setErrMsg(err.message);
				LogError("Error Occurred, Could not create Entity", err);
			}
			errRef.current.focus();
		}
	};

	return (
		<>
			{Seo("Add Entity")}
			<section>
				<div className="bg-white pb-8 sm:pb-12 lg:pb-12">
					<div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-36">
						<div className="flex justify-center items-center text-center w-full">
							<div className="flex flex-col h-full justify-center items-center">
								<div className="sm:max-w-xl">
									<div className="mb-8 uppercase text-base font-medium text-brandblack">
										Almost done
									</div>
									<h1 className="text-4xl font-bold text-brandblack tracking-tight sm:text-5xl lg:text-4xl">
										Connect your account
									</h1>
									{!FBSession && (
										<div className="mt-8">
											<FacebookLogin
												className="bg-brandteal text-brandwhite hover:bg-brandorange px-3 py-3 rounded-sm"
												appId={process.env.REACT_APP_FACEBOOK_ID}
												autoLoad={true}
												fields="name,email,picture"
												scope="email, pages_show_list, instagram_basic, instagram_manage_messages, pages_read_engagement, pages_manage_metadata, public_profile, instagram_manage_insights"
												onSuccess={logUserIntoFB}
												icon="fa-facebook"
											>Authenticate with Facebook</FacebookLogin>
										</div>
									)}

									{FBSession && (
										<div className="mt-8">
											<Formik
												initialValues={{
													page: "",
													instagramHandle: "",
													entityName: "",
												}}
												validate={(values) => {
													const errors = {};
													if (!values.page) {
														errors.page =
															"Please Select a Page";
													}

													if (
														!values.instagramHandle
													) {
														errors.instagramHandle =
															"This field is required";
													}
													if (!values.entityName) {
														errors.entityName =
															"This field is required";
													}
													return errors;
												}}
												onSubmit={(
													values,
													{ setSubmitting }
												) => {
													values.instagramHandle =
														values.instagramHandle.trim();
													values.entityName =
														values.entityName.trim();
													setTimeout(() => {
														createEntity(values);
														setSubmitting(false);
													}, 400);
												}}
											>
												{({ isSubmitting }) => (
													<Form className="flex flex-col justify-center items-center mt-6">
														<Field
															name="page"
															as="select"
															className="w-3/4 my-1.5 px-3 py-2 border border-brandblack rounded-none shadow-sm text-sm font-normal text-brandblack bg-transparent focus:outline-none"
														>
															<option value={""}>
																Select a Page
															</option>
															{FBPages &&
																FBPages.map(
																	(page, i) => (
																		<option
																			key={i}
																			value={page.id}
																		>
																			{page.name}
																		</option>
																	)
																)}
														</Field>
														<ErrorMessage
															name="page"
															component="div"
															className="mb-1 text-brandred w-3/4 flex flex-row justify-start items-start"
														/>
														<Field
															placeholder="Instagram Handle"
															className="w-3/4 my-1.5 px-3 py-2 border border-brandblack rounded-none shadow-sm text-sm font-normal text-brandblack bg-transparent focus:outline-none"
															type="text"
															name="instagramHandle"
														/>
														<ErrorMessage
															name="instagramHandle"
															component="div"
															className="mb-1 text-brandred w-3/4 flex flex-row justify-start items-start"
														/>
														<Field
															placeholder="Entity Name"
															className="w-3/4 my-1.5 px-3 py-2 border border-brandblack rounded-none shadow-sm text-sm font-normal text-brandblack bg-transparent focus:outline-none"
															type="text"
															name="entityName"
														/>
														<ErrorMessage
															name="entityName"
															component="div"
															className="mb-1 text-brandred w-3/4 flex flex-row justify-start items-start"
														/>
														<button
															type="submit"
															disabled={
																isSubmitting
															}
															className="mt-2 w-3/4 items-center justify-center px-6 py-2 text-sm text-center font-medium border-0 rounded-none bg-brandorange text-white hover:underline"
														>
															Submit
														</button>
													</Form>
												)}
											</Formik>
										</div>
									)}
									<p className="text-sm text-brandred">
										{errMsg}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default AddEntity;
