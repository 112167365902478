import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { setContacts } from "../../../features/contacts/contactsSlice";

import fb from '../../../api/fb'
import axios from "../../../api/axios";
import { useDispatch } from "react-redux";
import ReAuth from "../ReAuth";
import { XIcon, PencilAltIcon } from '@heroicons/react/outline';
import { trackPromise } from 'react-promise-tracker';
import DeleteContact from './DeleteContact';
import EditContact from "./Contacts/EditContact";
import { LogError } from "../../../utils/LogError";

const Contacts = ({ listValue }) => {
	const dispatch = useDispatch();
	const [contactsObj, setContactsObj] = useState(JSON.parse(sessionStorage.getItem("contacts")));
	const [openAuth, setOpenAuth] = useState(false);
	const [open, setOpen] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const [contactId, setContactId] = useState(null);
	const [contactEdit, setContactEdit] = useState(null);
	const userSession = sessionStorage.getItem('userSession');
	const FBPageAccess = sessionStorage.getItem('FBPageAccess');
	var contactList = [];

	if (listValue !== 'All') {
		contactsObj.filter(function (contact) {
			if (contact.lists?.data[0]?.id === listValue) {
				contactList.push(contact)
			}
		});
	} else {
		contactList = contactsObj;
	}

	useEffect(() => {
		const getContacts = async () => {
			const contactsArray = [];
			try {
				const response = await axios.get("/api/contacts?populate=*",
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${userSession}`,
						},
					}
				)
				response?.data?.data.map(async (contact) => {
					var data = {
						id: contact.id,
						...contact.attributes,
						details: {}
					};
					try {
						const fbResponse = await fb.get(`/${contact.attributes.instagramSId}?access_token=${FBPageAccess}&fields=name,profile_pic,follower_count,is_user_follow_business,is_business_follow_user`);
						data.details['id'] = fbResponse?.data?.id;
						data.details['name'] = fbResponse?.data?.name;
						data.details['follower_count'] = fbResponse?.data?.follower_count;
						data.details['profile_pic'] = fbResponse?.data?.profile_pic ? fbResponse?.data?.profile_pic : '';

						contactsArray.push({ ...data });
					} catch (err2) {
						if (err2.code === 109) {
							LogError('Error Occurred, Getting Instagram Details of Contact', err2)
							console.log('cont1', err2);
							sessionStorage.removeItem('fbSession');
							setOpenAuth(true)
						}
					}
				});
			} catch (err) {
				console.log(err)
				LogError('Error Occurred, Getting Contacts', err)
			}
			setTimeout(() => {
				setContactsObj(contactsArray)
				dispatch(setContacts(contactsArray));
			}, 500)
		}
		getContacts();
	}, [FBPageAccess, userSession, dispatch]);

	const handleContactDeleteConfirmation = (id) => {
		setOpen(true)
		setContactId(id)
	}
	const handleEditContact = (contact) => {
		setOpenEdit(true)
		setContactEdit(contact)
	}


	return (
		<div>
			<ul className="divide-y divide-gray-200">
				{contactList &&
					contactList.map((contact) => (
						<li
							className="py-4 px-2 hover:bg-brandeggshell"
							key={contact.id}
						>

							<div className="flex space-x-10 items-center">
								<div className="relative">
									<Link
										to={`/app/contact/${contact.id}/${contact.instagramHandle}`}
									>
										<img
											src={contact.details?.profile_pic ? contact.details?.profile_pic : '/people/default-user.png'}
											alt=""
											className="w-[48px] h-auto rounded-full"
										/>
									</Link>
								</div>
								<div className="flex-1 space-y-1">
									<Link
										to={`/app/contact/${contact.id}/${contact.instagramHandle}`}
									>
										<div className="flex-col justify-between items-center gap-3">
											<div className="text-sm font-normal text-brandblack">
												{contact.firstName}{" "}
												{contact.lastName}
											</div>
										</div>
										<div className="flex justify-between">
											<div className="flex justify-between text-sm text-brandgray">
												<span className="">
													{contact.details.follower_count} Followers
												</span>
											</div>
										</div>
									</Link>


								</div>

								<div className="relative">
									<PencilAltIcon className="h-[1.25rem] w-[1.25rem] hover:text-brandteal hover:cursor-pointer text-brandorange" onClick={() => handleEditContact(contact)} />
									<XIcon className="h-[1.25rem] w-[1.25rem] hover:text-brandteal hover:cursor-pointer text-brandorange" onClick={() => handleContactDeleteConfirmation(contact.id)} />
								</div>
							</div>
						</li>


					))}

				{!contactsObj && <div>No Contacts</div>}
			</ul>
			{openEdit && <EditContact openEdit={openEdit} setOpenEdit={setOpenEdit} contact={contactEdit} />}
			{open && <DeleteContact open={open} setOpen={setOpen} contactId={contactId} />}
			<ReAuth openAuth={openAuth} setOpenAuth={setOpenAuth} />
		</div>
	);
};

export default Contacts;
