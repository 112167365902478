import { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Loader from "react-loader-spinner";
import { usePromiseTracker } from "react-promise-tracker";
import AOS from "aos";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "aos/dist/aos.css";
import { useSelector } from "react-redux";
import ReactGA from "react-ga4";

//Components
import SiteLayout from "./components/site/SiteLayout";
import LandingPage from "./views/site/LandingPage";
import Login from "./views/site/Login";
import Signup from "./views/site/Signup";
import Privacy from "./views/site/Privacy";
import Terms from "./views/site/Terms";
import AppLayout from "./components/app/AppLayout";
import Dashboard from "./views/app/Dashboard";
import AddEntity from "./views/app/AddEntity";
import SetEntity from "./views/app/SetEntity";
import Conversation from "./views/app/Conversation";
import Contact from "./views/app/Contact";
import { ReactComponent as TomLogo } from './assets/tom-logo.svg'
import Conversations from './views/app/Conversations';
import Contacts from "./views/app/Contacts";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
ReactGA.initialize(trackingId);

const trackPageView = (location, userId = null, email = null) => {
	ReactGA.send("pageview", { page_path: location.pathname });

	if (userId && email) {
		ReactGA.set({ user_id: userId, user_email: email });
	}
};

function App() {
	const LoadingIndicator = (props) => {
		const { promiseInProgress } = usePromiseTracker();
		useEffect(() => {
			AOS.init({
				duration: 600,
			});
		}, []);

		return (
			promiseInProgress && (
				<div
					style={{
						backgroundColor: "#FFFFFF",
						width: "100%",
						height: "100vh",
						overflow: "auto",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						position: "absolute",
						zIndex: 10000,
					}}
				>
					<TomLogo className="fill-brandorange absolute" />
					<Loader
						type="Oval"
						color="#F89767"
						height={120}
						width={120}
					/>
				</div>
			)
		);
	};
	useSelector((state) => state.user.user);
	const location = useLocation();
	const session = sessionStorage.getItem("userSession");
	const user = sessionStorage.getItem("user");
	const userId = user ? JSON.parse(user).id : null;
	const userEmail = user ? JSON.parse(user).email : null;

	useEffect(() => {
		trackPageView(location, userId, userEmail);
	}, [location, userId, userEmail]);
	useEffect(() => {
		if (userId && userEmail) {
			window.userId = userId;
			window.userEmail = userEmail;
		}
	}, [userId, userEmail]);
	return (
		<>
		<ToastContainer/>
			{/* isLoading State */}
			<LoadingIndicator />

			{/* Site Routes */}
			{!session && (
				<Routes>
					<Route path="/" element={<SiteLayout />}>
						<Route index element={<LandingPage />} />
						<Route path="/login" element={<Login />} />
						<Route path="/signup" element={<Signup />} />
						<Route path="/privacy-policy" element={<Privacy />} />
						<Route path="/terms-and-services" element={<Terms />} />
						<Route path="*" element={<Navigate to="/" replace />} />
					</Route>
				</Routes>
			)}

			{/* App Routes */}
			{session && (
				<Routes>
					<Route path="/app" element={<AppLayout />}>
						<Route index element={<Dashboard />} />
						<Route path="add-entity" element={<AddEntity />} />
						<Route path="set-entity" element={<SetEntity />} />
						<Route path="conversation" element={<Conversation />} />
						<Route path="conversations" element={<Conversations />} />
						<Route path="contacts" element={<Contacts />} />
						<Route path="contact/:id/:handle" element={<Contact />} />
						<Route
							path="*"
							element={<Navigate to="/app" replace />}
						/>
					</Route>
				</Routes>
			)}

		</>
	);
}

export default App;
