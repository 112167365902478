import React from "react";

const TwoColIcons = ({ left, content }) => {
	return (
		<section>
			{left ? (
				<div className="bg-brandnewgray pb-8 sm:pb-12 lg:pb-12">
					<div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-36">
						<div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
							<div className="flex h-full w-full mx-auto -mt-5 justify-center lg:justify-right items-center lg:text-right lg:w-5/6 lg:ml-28">
								<div className="mt-8 sm:max-w-xl">
									<p className="mb-8 uppercase text-base font-semibold text-brandblack"> {content.superText}</p>
									<h1 className="text-2xl font-bold text-brandblack tracking-tight sm:text-3xl md:text-3xl lg:text-right lg:text-4xl">
										{content.header}
									</h1>
									<div className="grid grid-cols-2 gap-16 mt-8">
										<div className="flex flex-col lg:text-right">
											<p className="mb-4 font-bold text-brandblack">{content.leftColHeader}</p>
											<p className="mb-2 text-brandblack">{content.leftColFirst}</p>
											<p className="mb-2 text-brandblack">{content.leftColSecond}</p>
										</div>
										<div className="flex flex-col lg:text-right">
											<p className="mb-4 font-bold text-brandblack">{content.rightColHeader}</p>
											<p className="mb-2 text-brandblack">{content.rightColFirst}</p>
											<p className="mb-2 text-brandblack">{content.rightColSecond}</p>
										</div>
									</div>
								</div>
							</div>
							<div className="relative mt-10 w-full h-auto sm:h-72 md:h-96 lg:mt-0 lg:w-auto lg:h-auto">
								<img
									className="relative inset-0 mx-auto w-1/2 h-full w-full object-contain "
									src={content.image}
									alt=""
								/>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className="bg-brandnewgray pb-8 sm:pb-12 lg:pb-12 slant-blue">
					<div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-36">
						<div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-32">
							<div className="flex flex-col h-full w-full mx-auto text-center justify-center items-center lg:justify-right lg:text-right lg:w-5/6 lg:ml-28">
								<div className="w-full uppercase text-base text-center font-medium text-brandblack lg:text-right">
									{content.superText}
								</div>
								<div className="mt-8 sm:max-w-xl">
									<h1 className="text-2xl text-center font-bold text-brandblack tracking-tight sm:text-3xl md:text-3xl lg:text-right lg:text-4xl">
										{content.header}
									</h1>
								</div>
							</div>
							<div className="relative mt-10 w-full h-auto sm:h-72 md:h-96 lg:mt-0 lg:w-auto lg:h-auto">
								<div className="grid grid-cols-3 gap-16 mt-16">
									<div className="flex flex-col justify-center items-center">
										<img
											className="relative mb-5 w-1/4 h-auto object-contain"
											src="/icons/unlock@2x.png"
											alt=""
										/>
										<img
											className="relative w-full h-auto object-contain"
											src="/people/sarah_profile.png"
											alt=""
										/>
										<div className="text-center text-brandblack text-base mt-5">
											@sarah_z
										</div>
										<div className="text-center text-brandgray text-sm">
											132.5K Followers
										</div>
									</div>
									<div className="flex flex-col justify-center items-center">
										<img
											className="relative w-1/5 mb-5 h-auto object-contain"
											src="/icons/locked@2x.png"
											alt=""
										/>
										<img
											className="relative w-1/2 h-auto object-contain"
											src="/people/sam_profile.png"
											alt=""
										/>
										<div className="text-center text-brandblack text-base mt-5">
											Sam
										</div>
										<div className="text-center text-brandgray text-sm">
											Manager
											<br /> Can't Post
										</div>
									</div>
									<div className="flex flex-col justify-center items-center">
										<img
											className="relative w-1/5 mb-5 h-auto object-contain"
											src="/icons/locked@2x.png"
											alt=""
										/>
										<img
											className="relative w-1/2 h-auto object-contain"
											src="/people/karl_profile.png"
											alt=""
										/>
										<div className="text-center text-brandblack text-base mt-5">
											Karl
										</div>
										<div className="text-center text-brandgray text-sm">
											Customer Service
											<br /> Can't Post
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</section>
	);
};

export default TwoColIcons;
