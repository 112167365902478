import { Fragment, useState, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { Link, useLocation } from "react-router-dom";
import {
  MenuIcon,
  XIcon,
} from '@heroicons/react/outline';
import { ReactComponent as TomLogo } from '../../assets/tom-logo.svg'
import TomLogoPng from '../../assets/ToM_Lockup_Beta.png'

const Header = () => {
	const [open, setOpen] = useState(false); // Add this state variable
  const location = useLocation();

  useEffect(() => {
    setOpen(false); // Close the menu when location changes
  }, [location]);
	return (
		<Popover className="relative bg-brandwhite w-full">
			<div className="max-w-full mx-auto px-4 sm:px-6">
				<div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
					<div className="hidden md:flex justify-start lg:w-0 lg:flex-1">
						<Link
							to="/demo"
							className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 text-sm font-semibold text-brandteal hover:underline"
						>
							View Demo
						</Link>
					</div>

					<div className="flex justify-center items-center">
						<Link to="/">
							<img src={TomLogoPng} className="h-16 lg:w-auto" alt="Top of Mind Logo" />
						</Link>
					</div>

					<div className="-mr-2 -my-2 md:hidden">
						<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none" onClick={() => setOpen(!open)}>
							<span className="sr-only">Open menu</span>
							<MenuIcon className="h-6 w-6" aria-hidden="true" />
						</Popover.Button>
					</div>

					<div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
						<Link
							to="/signup"
							className="whitespace-nowrap text-sm font-semibold text-brandteal hover:underline"
						>
							Sign up
						</Link>
						<Link
							to="/login"
							className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-6 py-2 border border-brandteal rounded-none shadow-sm text-sm font-semibold text-brandteal bg-transparent hover:underline"
						>
							Log in
						</Link>
					</div>
				</div>
			</div>

			<Transition
				as={Fragment}
				enter="duration-200 ease-out"
				enterFrom="opacity-0 scale-95"
				enterTo="opacity-100 scale-100"
				leave="duration-100 ease-in"
				leaveFrom="opacity-100 scale-100"
				leaveTo="opacity-0 scale-95"
			>
				<Popover.Panel
    focus
    className={`absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-50 ${open ? '' : 'hidden'}`}
  >
					<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
						<div className="pt-5 pb-6 px-5">
							<div className="flex items-center justify-between">
								<div className="inline-block color-brandblack">
									<TomLogo
										className="w-full lg:h-full lg:w-auto lg:max-w-none"
									/>
									<div className="hidden lg:block text-brandblack text-lg ml-4">
										<span className="font-semibold">
											Top
										</span>{" "}
										of{" "}
										<span className="font-semibold">
											Mind
										</span>
									</div>
								</div>
								<div className="-mr-2">
									<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none ">
										<span className="sr-only">
											Close menu
										</span>
										<XIcon
											className="h-6 w-6"
											aria-hidden="true"
										/>
									</Popover.Button>
								</div>
							</div>
						</div>
						<div className="py-6 px-5 space-y-6">
							<div>
								<Link
									to="/demo"
									className="w-full flex items-center justify-center px-4 py-2 border-brandteal border rounded-none shadow-sm text-base font-normal text-brandteal bg-transparent hover:bg-brandteal hover:text-white"
								>
									View Demo
								</Link>
							</div>
							<div>
								<Link
									to="/signup"
									className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-none shadow-sm text-base font-normal text-white bg-brandteal hover:underline"
								>
									Sign up
								</Link>
								<p className="mt-6 text-center text-base font-medium text-gray-500">
									Existing customer?{" "}
									<Link
										to="/Login"
										className="text-brandteal hover:underline"
									>
										Log in
									</Link>
								</p>
							</div>
						</div>
					</div>
				</Popover.Panel>
			</Transition>
		</Popover>
	);
};

export default Header;
