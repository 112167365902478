import ContactsList from "../../components/app/dashboard/ContactsList";
import ConversationList from "../../components/app/dashboard/ConversationList";
import ProfileOverview from "../../components/app/dashboard/ProfileOverview";
import FollowerChart from "../../components/app/dashboard/FollowerChart";
import { Seo } from '../../utils/Seo';

const Dashboard = () => {
	return (
		<main className="mx-20 my-10 h-full flex flex-col items-center justify-center">
		{Seo('Dashboard')}
			<div className="m-6 items-center justify-between lg:grid lg:grid-cols-3 lg:gap-4 gap-4 w-full">
				<div className="flex py-4 bg-white overflow-hidden shadow rounded-md border-brandblack border-2 h-auto lg:col-span-1 lg:h-72 mx-auto lg:mx-0">
					<ProfileOverview />
				</div>
				<div className="flex lg:h-72 lg:col-span-2 lg:mx-0 mt-4 lg:mt-0 mx-auto py-4 bg-white overflow-hidden shadow rounded-md border-brandblack border-2">
					<FollowerChart />
				</div>
			</div>

			<div className="m-6 items-center justify-between grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 md:gap-4 lg:grid lg:grid-cols-2 lg:gap-4 w-full">
				<div className="bg-white overflow-hidden shadow rounded-md border-brandblack border-2 h-[32rem] lg:mx-0 sm:my-8">
					<ConversationList />
				</div>
				<div className="bg-white overflow-hidden shadow rounded-md border-brandblack border-2 h-[32rem] lg:mx-0 sm:my-8">
					<ContactsList />
				</div>
			</div>
		</main>
	);
};

export default Dashboard;
