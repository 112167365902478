import { useState, useEffect, useRef, useCallback } from "react";
import FacebookLogin from '@greatsumini/react-facebook-login';
import { useDispatch } from "react-redux";
import { updateUser } from "../../features/user/userSlice";
import { setEntity } from "../../features/entity/entitySlice";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import fb from "../../api/fb";
import axios from "../../api/axios";
import { Seo } from '../../utils/Seo';
import { LogError } from '../../utils/LogError';


function SetEntity() {
	const userSession = sessionStorage.getItem("userSession");
	let navigate = useNavigate();
	const dispatch = useDispatch();
	const [FBSession, setFBSession] = useState(
		sessionStorage.getItem("fbSession")
	);
	const [FBPages, setFBPages] = useState(false);
	const [showForm, setShowForm] = useState(false);
	const [entities, setEntities] = useState(false);
	const errRef = useRef();
	const [errMsg, setErrMsg] = useState("");

	// Log user in to fb set fb access token
	const logUserIntoFB = (response) => {
		sessionStorage.setItem("fbSession", response.accessToken);
		setFBSession(response.accessToken);
		setShowForm(false);
	};

	// if fb access token get pages
	useEffect(() => {
		const getFBPages = async () => {
			if (FBSession) {
				try {
					const response = await fb.get("/me/accounts/", {
						params: {
							access_token: FBSession,
						},
					});
					setFBPages(response?.data?.data);
				} catch (err) {
					console.error(err);
					setErrMsg("Error Occured, Please try again");
					LogError("Error Occurred, Getting Facebook Page", err);
					errRef.current.focus();
				}
			}
		};
		getFBPages();
	}, [FBSession]);

	// get user details
	useEffect(() => {
		const getUserDetails = async () => {
			try {
				const response = await axios.get("/api/users/me/details", {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${userSession}`,
					},
				});
				dispatch(updateUser(response?.data));
				setEntities(response?.data?.entities);
			} catch (err) {
				console.error(err);
				setErrMsg("Error Occured, Please try again");
				LogError("Error Occurred, Getting User Information", err);
				errRef.current.focus();
			}
		};
		getUserDetails()
	}, [userSession, dispatch]);

	const entityCount = useCallback(() => {
		var count = entities.length;
		if (count === 0) {
			navigate("/app/add-entity");
		} else if (count === 1) {
			var pageId = entities[0].fbApiKey;
			var currentEntity = entities.filter((entity) => {
				return entity.fbApiKey === pageId;
			});

			var currentPage = FBPages.filter((page) => {
				return page.id === pageId;
			});

			currentEntity = currentEntity[0];
			dispatch(setEntity(currentEntity));
			sessionStorage.setItem("FBPageId", pageId);
			sessionStorage.setItem("FBPageAccess", currentPage[0].access_token);

			navigate("/app");
		} else {
			setShowForm(true);
		}
	}, [dispatch, navigate, entities, FBPages]);

	setTimeout(() => {
		entityCount();
	}, 250);

	// if multiple - set selected entity with
	const setCurrentEntity = (form) => {
		var currentEntity = entities.filter((entity) => {
			return entity.fbApiKey === form.page;
		});

		var currentPage = FBPages.filter((page) => {
			return page.id === form.page;
		});

		currentEntity = currentEntity[0];
		dispatch(setEntity(currentEntity));
		sessionStorage.setItem("FBPageId", currentEntity.fbApiKey);
		sessionStorage.setItem("FBPageAccess", currentPage[0].access_token);

		navigate("/app");
	};

	return (
		<>
		{Seo("Set Entity")}
			<section>
				<div className="bg-white pb-8 sm:pb-12 lg:pb-12">
					<div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-36">
						<div className="flex justify-center items-center text-center w-full">
							<div className="flex flex-col h-full justify-center items-center">
								<div className="sm:max-w-xl">
									<div className="mb-8 uppercase text-base font-medium text-brandblack">
										Almost done
									</div>
									<h1 className="text-4xl font-bold text-brandblack tracking-tight sm:text-5xl lg:text-4xl">
										Set your account
									</h1>
									{!FBSession && (
										<div className="mt-8">
											<FacebookLogin
											className="bg-brandteal text-brandwhite hover:bg-brandorange px-3 py-3 rounded-sm"
												appId={process.env.REACT_APP_FACEBOOK_ID}
												autoLoad={true}
												fields="name,email,picture"
												scope="email, pages_show_list, instagram_basic, instagram_manage_messages, pages_read_engagement, pages_manage_metadata, public_profile, instagram_manage_insights"
												onSuccess={logUserIntoFB}
												icon="fa-facebook"
											>Authenticate with Facebook</FacebookLogin>
										</div>
									)}
									<a href="https://drive.google.com/file/d/1e-kN68tdYh3g8bpfF6elgG9dmz-Zw_jd/view" target="_blank" className="block mt-8">
										View the Tutorial Video
									</a>
									<a href="https://top-of-mind-backend.s3.amazonaws.com/Top+of+Mind+-+Demo+%26+Overview+-+2.8.23.pdf" target="_blank" className="block mt-8">
										Tutorial PDF
									</a>

									{FBSession && showForm && (
										<div className="flex flex-col">
											<div className="mt-8">
												<Formik
													initialValues={{ page: "" }}
													validate={(values) => {
														const errors = {};
														if (!values.page) {
															errors.page =
																"Please Select a Page";
														}
														return errors;
													}}
													onSubmit={(
														values,
														{ setSubmitting }
													) => {
														setTimeout(() => {
															setCurrentEntity(
																values
															);
															setSubmitting(
																false
															);
														}, 400);
													}}
												>
													{({ isSubmitting }) => (
														<Form className="flex flex-col justify-center items-center mt-6">
															<Field
																name="page"
																as="select"
																className="w-full my-1.5 px-3 py-2 border border-brandblack rounded-none shadow-sm text-sm font-normal text-brandblack bg-transparent focus:outline-none"
															>
																<option
																	value={""}
																>
																	Select a
																	Page
																</option>
																{entities &&
																	entities.map(
																		(
																			page,
																			i
																		) => (
																			<option
																				key={
																					i
																				}
																				value={
																					page.fbApiKey
																				}
																			>
																				{
																					page.entityName
																				}
																			</option>
																		)
																	)}
															</Field>
															<ErrorMessage
																name="page"
																component="div"
																className="mb-1 text-brandred text-center w-full flex flex-row justify-start items-start"
															/>

															<button
																type="submit"
																disabled={
																	isSubmitting
																}
																className="mt-2 w-full items-center justify-center px-6 py-2 text-sm text-center font-medium border-0 rounded-none bg-brandorange text-white hover:underline"
															>
																Select Entity
															</button>
														</Form>
													)}
												</Formik>
												<p className="text-sm text-brandred">
													{errMsg}
												</p>
											</div>
											<div className="mt-8">
												<Link
													to="/app/add-entity"
													className="uppercase text-brandorange font-bold text-base hover:underline"
												>
													Add New Entity
												</Link>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default SetEntity;
