import React from "react";

const ListItem = ({ active, listItem }) => {
	return (
		<div
			className={`text-xs my-2 font-normal ${
				active === listItem ? "text-brandblack" : "text-brandgray"
			} items-start pl-2 hover:cursor-pointer`}
		>
			{listItem}
		</div>
	);
};

export default ListItem;
