import React from "react";
import { Seo } from '../../utils/Seo';

const Privacy = () => {
	return (
		<main className="pb-8 sm:pb-12 lg:pb-12 bg-white">
		{Seo("Privacy Policy")}
			<div className="pt-8 text-left overflow-hidden sm:pt-12 lg:relative lg:py-36">
				<div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-20 lg:max-w-7xl">
					<h1 className="text-4xl text-center mb-8 font-bold text-brandblack tracking-tight sm:text-5xl lg:text-4xl">
						Privacy Policy
					</h1>
					<p>
					Effective January 12, 2023.  As a software platform built to improve the lives of our DM-heavy users, we treat privacy as the most important feature of our platform and offering.  None of your information pulled from Instagram is stored, shared, or used for any other purpose.  All this data is wholly owned by the user and consumer, and not by Top of Mind.  This policy is designed to build a high level of customer trust, knowing that we only intend to build a product that drives improved customer performance within their social networks.  The only information that we will have is your Top of Mind login, and billing information, all of which will be encrypted and secured by a third party processing system.  If you have any questions regarding our Privacy Policy, please reach out to your account representative.  As noted in our Terms & Services - by using our platform, Top of Mind, in any way, you explicitly accept this Privacy Policy and without exception indemnify Top of Mind in all situations, circumstances, or outcomes. 
					</p>
				</div>
			</div>
		</main>
	);
};

export default Privacy;
