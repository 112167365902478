import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const conversationSlice = createSlice({
	name: "conversations",
	initialState,
	reducers: {
		setConversations: (state, action) => {
			state.conversations = action.payload;
            sessionStorage.setItem('conversations', JSON.stringify(action.payload))
		},
		
	},
});

export const { setConversations } = conversationSlice.actions;
export default conversationSlice.reducer;
