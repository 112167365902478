import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { trackPromise } from "react-promise-tracker";
import { useSelector } from "react-redux";
import axios from "axios";
import fb from "../../api/fb";
import moment from "moment";
import ReAuth from "./ReAuth";
import { PaperAirplaneIcon, ChatIcon } from "@heroicons/react/outline";
import { useNavigate, useLocation } from "react-router-dom";
import { LogError } from "../../utils/LogError";


function Chat({ conversationId }) {
	let Navigate = useNavigate();
	let location = useLocation();
	const FBPageAccess = sessionStorage.getItem("FBPageAccess");
	const newMessage = useSelector((state) => state.socket.socket);
	const [conversations, setConversations] = useState(false);
	const [conversationContent, setConversationContent] = useState([]);
	const [myInstagramId, setMyInstagramId] = useState(false);
	const [contactInstagramId, setContactInstagramId] = useState(false);
	const [contactInstagramHandle, setContactInstagramHandle] = useState(false);
	const [openAuth, setOpenAuth] = useState(false);
	const [pendingMessages, setPendingMessages] = useState({ status: false, content: '', date: '' });
	const scrollableContainerRef = useRef(null);
	const topMessageRef = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const [nextPageURL, setNextPageURL] = useState(null);
	const [initialLoad, setInitialLoad] = useState(true);
	const [prevMessageHeight, setPrevMessageHeight] = useState(0);
	


	const handleConversation = (id, SGID, username) => {
		Navigate(`/app/conversation`, { state: { id: id, SGID: SGID, username: username } });
	};


	const scrollToBottom = () => {
		if (scrollableContainerRef.current) {
			scrollableContainerRef.current.scrollTop = scrollableContainerRef.current.scrollHeight;
		}
	};
	useEffect(() => {
		setConversations([]);
		setConversationContent([]);
		setNextPageURL(null);
		setIsLoading(false)
	}, [conversationId]);

	const fetchIndividualMessages = (messages) => {
		axios
			.all(
				messages.map((message) =>
					fb.get(`/${message.id}`, {
						params: {
							fields: "id,from,to,message,story",
							access_token: FBPageAccess,
						},
					})
				)
			)
			.then(axios.spread((...res) => {
				setConversationContent((prevContent) => {
					if (!Array.isArray(prevContent)) {
						prevContent = [];
					}
					return [...prevContent, ...res];
				});
			}))
			.catch((error) => {
				LogError("Error fetching individual messages", error);
			});
	};
	const mergeMessages = (newMessages) => {
		const updatedMessages = [...conversations.messages.data, ...newMessages];
		setConversations((prevState) => ({
			...prevState,
			messages: {
				...prevState.messages,
				data: updatedMessages,
			},
		}));
	};


	useEffect(() => {
		if (conversationId) {
			trackPromise(
				fb
					.get(`/${conversationId}`, {
						params: {
							fields: "messages,participants,updated_time",
							access_token: FBPageAccess,
						},
					})
					.then((response) => {
						setConversations(response.data);
						setMyInstagramId(response.data.participants.data[0].id);
						setContactInstagramId(response.data.participants.data[1].id);
						setContactInstagramHandle(response.data.participants.data[1].username);
						if (response.data.messages.paging?.next) {
							setNextPageURL(response.data.messages.paging.next);
						}
						axios
							.all(
								response.data.messages.data.map((message) =>
									fb.get(`/${message.id}`, {
										params: {
											fields: "id,from,to,message,story",
											access_token: FBPageAccess,
										},
									})
								)
							)
							.then(axios.spread((...res) => {
								setConversationContent(res);
								setInitialLoad(false);
							}))
							.catch((error) => {
								LogError("Error fetching individual messages", error);
							});

						// Remove this line
						// fetchIndividualMessages(response.data.messages.data);

					})
					.catch(console.log)
			);
		}
	}, [FBPageAccess, conversationId]);


	useEffect(() => {
		if (!initialLoad) {
		  const newHeight = scrollableContainerRef.current.scrollHeight;
		  const heightDifference = newHeight - prevMessageHeight;
		  scrollableContainerRef.current.scrollTop = heightDifference;
		} else {
		  scrollToBottom();
		}
	  }, [conversationContent]);



	useEffect(() => {
		if (!scrollableContainerRef.current) return;

		const handleScroll = () => {
			if (
			  scrollableContainerRef.current.scrollTop === 0 &&
			  nextPageURL &&
			  !isLoading
			) {
			  const prevHeight = scrollableContainerRef.current.scrollHeight;
			  setIsLoading(true);
			  axios
				.get(nextPageURL)
				.then((response) => {
				  fetchIndividualMessages(response.data.data);
				  mergeMessages(response.data.data);
		  
				  if (response.data?.paging?.next) {
					setNextPageURL(response.data?.paging.next);
				  } else {
					setNextPageURL(null);
				  }
				  setIsLoading(false);
				  setPrevMessageHeight(prevHeight);
				})
				.catch((error) => {
					LogError("Error fetching next page of messages", error);
				});
				
			}
		  };
		  

		scrollableContainerRef.current.addEventListener("scroll", handleScroll);
		return () => {
			if (scrollableContainerRef.current) {
				scrollableContainerRef.current.removeEventListener("scroll", handleScroll);
			}
		};
	}, [scrollableContainerRef, nextPageURL, isLoading]);

	const getMessageDate = (id) => {
		const selectedPage = conversations.messages.data.find((foundId) => foundId.id === id);
		return moment(selectedPage?.created_time).format("MM/DD/YY, h:mm a");
	};

	const determineSender = (userID) => userID === myInstagramId;

	const sendMessage = (values) => {
		const messageData = {
			recipient: { id: contactInstagramId },
			message: { text: values.message },
			messaging_type: "MESSAGE_TAG",
			tag: "HUMAN_AGENT",
		};

			fb
				.post(`/me/messages`, messageData, {
					params: { access_token: FBPageAccess },
				})
				.then((response) => {
					setPendingMessages({ status: true, content: messageData.message.text, date: moment(Date.now()).format("MM/DD/YY, h:mm a") });
					scrollToBottom();


				})
				.catch((error) => {
					sessionStorage.removeItem("fbSession");
					LogError("Error sending message", error);
					setOpenAuth(true);
					console.log("chat2:", error);
				});
	};

	useEffect(() => {
		if (newMessage) {
			setTimeout(() => {
				const data = {
					data: {
						message: newMessage.message,
						from: { id: newMessage.senderId },
					},
				};
				setPendingMessages({ status: false, content: '', date: '' });
				setConversationContent((conversationContent) => [data, ...conversationContent]);
			}, 4000);
		}
	}, [newMessage]);
	


	return (
		<>
			<div className="px-4 py-5 sm:px-6 border-b-2 border-brandblack text-brandblack text-base font-bold leading-5 tracking-widest flex items-center">

				<span className="text-sm text-brandblack capitalize ml-4 font-normal">

					<a href={`https://www.instagram.com/${contactInstagramHandle}/`}
						target={"_blank"}
						rel="noreferrer" className="text-brandnavy">
						{contactInstagramHandle}
					</a>
				</span>
				{location.pathname !=="/app/conversation" &&
				<div className={`ml-auto flex flex-row items-center space-x-1 hover:text-brandteal hover:cursor-pointer text-brandorange text-right font-normal`}
					onClick={() => handleConversation(conversationId, contactInstagramId, contactInstagramHandle)}>
					<ChatIcon className="h-[1.5rem] w-[1.5rem]" />&nbsp;<span className="md:inline hidden">View Conversation</span>
				</div>}
			</div>

			<div
				ref={scrollableContainerRef}
				className="px-4 py-2 sm:p-6 h-full max-h-[450px] overflow-y-scroll"
			>
				{isLoading && (
					<div className="text-center text-sm font-semibold text-brandnavy">
						Loading
						<span
							className="dot inline-block w-2 h-2 mx-1 rounded-full bg-brandnavy animate-pulse"
							style={{ animationDelay: '0s' }}
						></span>
						<span
							className="dot inline-block w-2 h-2 mx-1 rounded-full bg-brandnavy animate-pulse"
							style={{ animationDelay: '0.2s' }}
						></span>
						<span
							className="dot inline-block w-2 h-2 mx-1 rounded-full bg-brandnavy animate-pulse"
							style={{ animationDelay: '0.4s' }}
						></span>
					</div>
				)}
				{conversationContent && (
					<div ref={topMessageRef} className="hidden">
						Loading...
					</div>
				)}
				{/* Content goes here */}

				{conversationContent &&
					conversationContent.slice().reverse().map((message, i) => (
						<div key={i}>
							{determineSender(message.data.from.id) ? (
								<div className="flex justify-end m-2">
									<div className="text-sm text-brandwhite py-1 pl-2 pr-4 bg-brandteal rounded-md w-2/3 flex flex-col">
										<div>{message.data.message}</div>
										<span className="text-xs pt-2">
											{getMessageDate(message.data.id)}
										</span>
									</div>
								</div>
							) : (
								<div className="flex justify-start m-2">
									<div className="text-sm text-brandblack bg-brandlightgray rounded-md py-1 px-2 w-2/3 flex flex-col">
										{message.data?.story ?
											(
												<div>
													<img src={message.data?.story?.mention ? message.data.story.mention.link : message.data.story.reply_to.link} />
													{message.data?.story?.mention && <p>{contactInstagramHandle} mentioned you in a story.</p>}
													{message.data?.story?.reply_to && <p><sub className="text-brandteal">{contactInstagramHandle} replied to your story</sub><br />{message.data.message}</p>}
												</div>
											)
											:
											(
												<div>{message.data.message}</div>
											)}
										<span className="text-xs pt-2">
											{getMessageDate(message.data.id)}
										</span>
									</div>
								</div>
							)}
						</div>
					))}
				{pendingMessages?.status &&
					<div>
						<div className="flex justify-end m-2">
							<div className="text-sm text-brandblack py-1 pl-2 pr-4 bg-brandgray rounded-md w-2/3 flex flex-col">
								<div>{pendingMessages?.content}</div>
								<span className="text-xs pt-2">
									{pendingMessages.date}
								</span>
							</div>
						</div>
					</div>
				}
			</div>
			<div className=" px-4 py-4 sm:px-6 border-t-2 border-brandblack">
				{/* CHAT INPUT / SUBMIT */}
				<Formik
					initialValues={{ message: "" }}
					validate={(values) => {
						const errors = {};
						if (!values.message) {
							errors.message = "";
						}

						return errors;
					}}
					onSubmit={(values, { resetForm }) => {
						sendMessage(values);

						resetForm();
					}}
				>
					{({ isSubmitting }) => (
						<Form className="flex flex-col justify-center items-betweem ">
							<div className="flex flex-row justify-between items-center">
								<Field
									placeholder="New Message"
									className="w-3/4 my-1.5 px-3 py-2 border border-brandgray rounded-none shadow-sm text-sm font-normal text-brandblack bg-transparent focus:outline-none"
									type="text"
									name="message"
								/>
								<button
									type="submit"
									disabled={isSubmitting}
									className="text-sm text-center font-medium border-none rounded-none text-brandorange hover:text-brandteal"
								>
									<PaperAirplaneIcon className="rotate-90 h-[1.5rem] w-[1.5rem] mx-auto" /> Send
								</button>
							</div>

							<ErrorMessage
								name="message"
								component="div"
								className="mb-1 text-brandred w-3/4 flex flex-row justify-start items-start"
							/>
						</Form>
					)}
				</Formik>
			</div>
			<ReAuth openAuth={openAuth} setOpenAuth={setOpenAuth} />
		</>
	);
}

export default Chat;
