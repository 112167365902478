import React, { useState, useEffect, useRef, useMemo } from "react";
import { UserCircleIcon } from "@heroicons/react/solid";
import { PlusCircleIcon } from "@heroicons/react/outline";
import ReactTooltip from "react-tooltip";
import { trackPromise } from "react-promise-tracker";
import fb from "../../../api/fb";
import axios from "../../../api/axios";
import {
	differenceInCalendarDays,
	differenceInMinutes,
	differenceInHours,
} from "date-fns";
import { useNavigate } from "react-router-dom";
import AddContact from "./Contacts/AddContact";
import { useDispatch, useSelector } from "react-redux";
import { setConversations } from "../../../features/conversations/conversationSlice";
import { updateEntity } from "../../../features/entity/entitySlice";
import ReAuth from "../ReAuth";
import { LogError } from "../../../utils/LogError";

const MessageList = ({ activeItem }) => {
	let navigate = useNavigate();
	const dispatch = useDispatch();
	const userSession = sessionStorage.getItem("userSession");
	const userInfo = sessionStorage.getItem("user");
	const entity = JSON.parse(sessionStorage.getItem("entity"));
	const FBPageAccess = sessionStorage.getItem("FBPageAccess");
	const FBPageId = entity.fbApiKey;
	const [conversation, setConversation] = useState([]);
	const [entitySGID, setEntitySGID] = useState(null);
	const [open, setOpen] = useState(false);
	const [openAuth, setOpenAuth] = useState(false);
	const [addIgHandle, setAddIgHandle] = useState(null);
	const [addScopeId, setAddScopeId] = useState(null);
	const [convoId, setConvoId] = useState(null);
	const errRef = useRef();
	const [errMsg, setErrMsg] = useState("");
	const newMessage = useSelector((state) => state.socket.socket);

	const getConversationDetails = async (convoId, access_token) => {
		const { data } = await fb.get(`/${convoId}`, {
			params: {
				fields: "messages,participants,updated_time",
				access_token,
			},
		});
		return data;
	};

	const getLatestMessage = async (msgId, access_token) => {
		const { data } = await fb.get(`/${msgId}`, {
			params: {
				fields: "id,from,to,message,story",
				access_token,
			},
		});
		return data;
	};

	useEffect(() => {
		trackPromise(
			fb
				.get(`/${FBPageId}/conversations`, {
					params: {
						platform: "instagram",
						access_token: FBPageAccess,
					},
				})
				.then(async (response) => {
					const convos = response.data.data;
					dispatch(setConversations(convos));

					const conversationsPromises = convos.map(async (singleConvo) => {
						const convo = await getConversationDetails(singleConvo.id, FBPageAccess);

						if (entitySGID !== convo.participants.data[0].id) {
							setEntitySGID(convo.participants.data[0].id);
						}

						const lastestMessage = convo.messages.data[0];
						const latestMessageData = await getLatestMessage(lastestMessage.id, FBPageAccess);

						convo["latestMessage"] = latestMessageData.message;
						return convo;
					});

					const conversations = await Promise.all(conversationsPromises);
					setConversation(conversations);
				})
				.catch((err) => {
					sessionStorage.removeItem("fbSession");
					setOpenAuth(true);
					LogError("Error Occurred, Instagram Conversation", err);
				})
		);
	}, [FBPageAccess, FBPageId, dispatch]);

	useEffect(() => {
		if (entitySGID) {
			const setEntityScopeId = async () => {
				let entityData = {
					sgId: entitySGID,
				};

				try {
					const response = await axios.get(
						`/api/entities/${entity.id}?populate=*`,
						{ data: entityData },
						{
							headers: {
								"Content-Type": "application/json",
								Authorization: `Bearer ${userSession}`,
							},
						}
					);
					dispatch(updateEntity(response?.data));
				} catch (err) {
					if (err.response?.status === 400) {
						setErrMsg("Error Getting Entity");
						LogError("Error Occurred, Getting Entity 400 Error", err);
					} else {
						setErrMsg(err.message);
						LogError("Error Occurred, Getting Entity, Message List", err);
					}
					errRef.current.focus();
				}
			};

			setEntityScopeId();
		}
	}, []);

	const dateDiff = (timestamp) => {
		const current = new Date();
		const inputDate = new Date(timestamp);

		var minutes = differenceInMinutes(current, inputDate);
		var hours = differenceInHours(current, inputDate);
		var days = differenceInCalendarDays(current, inputDate);

		if (minutes < 60) {
			return `${minutes}m`;
		}
		if (hours < 24) {
			return `${hours}h`;
		} else {
			return `${days}d`;
		}
	};

	const handleConversation = (id, SGID, username) => {
		navigate(`/app/conversation`, { state: { id: id, SGID: SGID, username: username } });
	};

	const handleSortConversation = (sorter) => {
		var sorted_conversation = [];
		if (sorter === "Newest First") {
			sorted_conversation = conversation.sort((a, b) => {
				return new Date(a.updated_time).getTime() -
					new Date(b.updated_time).getTime()
			}).reverse();
		} else if (sorter === "Oldest First") {
			sorted_conversation = conversation.sort((a, b) => {
				return new Date(a.updated_time).getTime() -
					new Date(b.updated_time).getTime()
			});
		} else {
			sorted_conversation = conversation.sort((a, b) => {
				return a.participants.data[1].username > b.participants.data[1].username ? 1 : -1;
			});
		}
		return (sorted_conversation);
	};

	return (
		<div>
			<ul className="divide-y divide-gray-200">
				{handleSortConversation(activeItem)?.map((convo) => (
					<li
						className="py-4 px-2 hover:bg-brandeggshell"
						key={convo.id}
					>
						<div className="flex space-x-3 items-center cursor-pointer">

							<div
								className="flex-1 space-y-1 mx-2"
								onClick={() => handleConversation(convo.id, convo.participants.data[1].id, convo.participants.data[1].username)}
							>
								<div className="flex-col justify-between items-center gap-3">
									<div className="text-sm font-normal text-brandblack">
										{convo.participants.data[1].username}
									</div>
								</div>
								<div className="flex justify-between">
									<div className="flex justify-between text-sm text-brandgray">
										<span className="">
											{newMessage?.senderId === convo.participants.data[1].id ?
												newMessage?.message?.length > 100 ?
													`${newMessage?.message.slice(0, 100)}...`
													: newMessage?.message
												:
												convo.latestMessage.length > 100
													? `${convo.latestMessage.slice(
														0,
														100
													)}...`
													: convo.latestMessage
											}
											{convo.latestMessage === "" ? "Story Mention" : ""}
										</span>
										<span className="ml-4">
											{newMessage?.senderId === convo.participants.data[1].id ?
												<span className="text-brandblack">NEW</span>
												:
												dateDiff(convo.updated_time)
											}
										</span>
									</div>
								</div>
							</div>
							<div>
								<ReactTooltip
									border={true}
									borderColor={"rgb(62 82 97)"}
									backgroundColor={"#FFF"}
									textColor={"text-brandBlack"}
								/>
								<PlusCircleIcon
									data-tip="Add Contact"
									onClick={() => {
										setOpen(true);
										setAddIgHandle(
											convo.participants.data[1].username
										);
										setAddScopeId(
											convo.participants.data[1].id
										);
										setConvoId(convo.id);
									}}
									className="hover:text-brandblack hover:cursor-pointer text-brandteal mr-4 h-[1.5rem] w-[1.5rem]"
								/>
							</div>
						</div>
					</li>
				))}
			</ul>
			{/* MODAL CONTENT */}
			<AddContact
				open={open}
				setOpen={setOpen}
				igHandle={addIgHandle}
				scopeId={addScopeId}
				entity={entity.id}
				owner={userInfo.id}
				convoId={convoId}
			/>
			<ReAuth openAuth={openAuth} setOpenAuth={setOpenAuth} />
		</div>
	);
};

export default MessageList;